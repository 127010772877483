import React from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js"
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import profile from "assets/img/RLogohalfsize.png";
import styles from "assets/jss/material-kit-react/views/treatmentPage.js";
//sections
import WhatToExpectFAQPanel from "components/FAQs/WhatToExpectFAQ.js";
//pictures
import clinic1 from "assets/img/office/KetamineRoom1Gilbert.jpg";
import clinic2 from "assets/img/office/KetamineRoom4Gilbert.jpg";
import clinic3 from "assets/img/office/KetamineRoom3Gilbert.jpg";
import clinic4 from "assets/img/office/KetamineRoom2Gilbert.jpg";
import clinic5 from "assets/img/office/KetamineRoom1Chandler.jpg";
import clinic6 from "assets/img/office/KetamineRoom2Chandler.jpg";
import clinic7 from "assets/img/office/KetamineRoom3Chandler.jpg";
import clinic8 from "assets/img/office/KetamineRoomPHX.jpg";
import clinic9 from "assets/img/office/KetamineRoomAH.jpg";

const useStyles = makeStyles(styles);

export default function WhatToExpectPage(props) {
    const classes = useStyles();
    const { ...rest } = props;
    const imageClasses = classNames(
        classes.imgRounded,
        classes.imgFluid,
    );
    return (
        <div>
            <HelmetProvider>
                <Helmet>
                    <title>Redemption Psychiatry | What To Expect From A Typical Ketamine Treatment</title>
                    <meta name="description" content="Our typical Ketamine Treatment is administered intravenously under the comfort and professional care of our highly experienced doctors and registered nurses with Ketamine infusion experience. You will be in a quiet, relaxing, private room with a comfortable recliner and soft lighting. Our professional staff strive to not only make sure that each patient has their own personal needs met, but also provide a climate of support, relaxation, safety and understanding." />
                    <meta property="og:locale" content="en_US" />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content="Redemption Psychiatry | What To Expect From A Typical Ketamine Treatment" />
                    <meta property="og:description" content="Our typical Ketamine Treatment is administered intravenously under the comfort and professional care of our highly experienced doctors and registered nurses with Ketamine infusion experience. You will be in a quiet, relaxing, private room with a comfortable recliner and soft lighting. Our professional staff strive to not only make sure that each patient has their own personal needs met, but also provide a climate of support, relaxation, safety and understanding." />
                    <meta property="og:url" content="https://www.rpsychinfusions.com/whattoexpect-page" />
                    <meta property="og:site_name" content="Redemption Psychiatry Ketamine Infusions" />
                    <meta property="og:image" content="https://my.datasphere.com/files/mydatasphere/styles/full_640/public/business/images/0419/1555625884_12657239_RedemptionPsychiatry_500x500.png?itok=Qrowvv_y" />
                    <meta property="og:image:secure_url" content="https://my.datasphere.com/files/mydatasphere/styles/full_640/public/business/images/0419/1555625884_12657239_RedemptionPsychiatry_500x500.png?itok=Qrowvv_y" />
                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:title" content="Redemption Psychiatry | What To Expect From A Typical Ketamine Treatment" />
                    <meta name="twitter:description" content="Our typical Ketamine Treatment is administered intravenously under the comfort and professional care of our highly experienced doctors and registered nurses with Ketamine infusion experience. You will be in a quiet, relaxing, private room with a comfortable recliner and soft lighting. Our professional staff strive to not only make sure that each patient has their own personal needs met, but also provide a climate of support, relaxation, safety and understanding." />
                    <meta name="twitter:image" content="https://my.datasphere.com/files/mydatasphere/styles/full_640/public/business/images/0419/1555625884_12657239_RedemptionPsychiatry_500x500.png?itok=Qrowvv_y" />
                    <link rel='dns-prefetch' href='//www.google.com' />
                    <link rel="canonical" href="https://www.rpsychinfusions.com/whattoexpect-page" />
                </Helmet>
            </HelmetProvider>
            <Header
                color="transparent"
                rightLinks={<HeaderLinks />}
                fixed
                changeColorOnScroll={{
                    height: 200,
                    color: "white"
                }}
                {...rest}
            />
            <Parallax small image={require("assets/img/wellness-955796_640.jpg")} />
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.profile}>
                    <div>
                        <img src={profile} alt="Redemption Psychiatry Building" className={imageClasses} />
                    </div>
                </div>
                <Container maxWidth="lg" component="main" className={classes.heroContent}>
                <div className={classes.name}>
                    <div className={classes.profile}>
                        <h2 className={classes.title}>What to expect from a typical infusion with us.</h2>
                        <h5 className={classes.description}> Keep in mind all patients have a different experience custom tailored to each individual's needs. Our providers and nurses take great pride in making sure that each patient has their own personal needs met, as each individuals experience can differ greatly from infusion to infusion.</h5>
                        <br></br><br></br>
                    </div>
                </div>
                </Container>
                <div className={classes.skewC}></div>
                <div className={classes.colorBlock}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <h2 className={classes.titleWhite}>
                                Before starting treatment
                            </h2><br></br>
                            <h5 className={classes.descriptionWhite}>
                                Your psychiatric provider must determine ketamine is an appropriate treatment for you. They will then order the ketamine nurse to administer the prescription. The first infusion appointment will take a little longer than subsequent infusions, but generally, you should plan for 90 min for the first infusion and 60 min for subsequent infusions. You will initially meet with the infusion nurse and review the risks and benefits of the procedure to obtain your consent prior to starting the treatment. This gives you and your family the opportunity to ask any last-minute questions you may have before infusion begins.
                            </h5><br></br>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <img className={classes.rightSide} src={clinic1} alt="anxiety treatment in phoenix arizona"></img>
                        </GridItem>
                    </GridContainer>
                </div>
                <div className={classes.skewCC}></div>
                <div className={classes.whiteBlock}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <img className={classes.leftSide} src={clinic2} alt="depression treatment in scottsdale arizona"></img>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <h2 className={classes.title}>
                                Preparing for the infusion.
                            </h2><br></br>
                            <h5 className={classes.description}>
                                You should wear comfortable clothing that allows easy access to your forearms for IV placement. Unless otherwise instructed, you should take your medications as you normally would but avoid taking any new medications or as needed medications for 8 hours prior to the procedure.
                            </h5><br></br>
                        </GridItem>
                    </GridContainer>
                </div>
                <div className={classes.skewC}></div>
                <div className={classes.colorBlock}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <h2 className={classes.titleWhite}>
                                The infusion setting.
                            </h2><br></br>
                            <h5 className={classes.descriptionWhite}>
                                You will be in a quiet, relaxing, private room with a comfortable recliner and soft lighting. There is space in the room for a friend or family member to stay with you for support. You can talk normally, but you shouldn’t expect to have much meaningful conversation since you will find that you’ll want a calm and quiet environment. We provide some optional items to help you stay relaxed and comfortable such as a soft blanket, fluffy pillows, water to sip, and noise cancelling headphones to block ambient sound and listen to music during the treatment.
                            </h5><br></br>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <img className={classes.rightSide} src={clinic3} alt="Redemption Psychiatry Ketamine Therapy Center"></img>
                        </GridItem>
                    </GridContainer>
                </div>
                <div className={classes.skewCC}></div>
                <div className={classes.whiteBlock}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <img className={classes.leftSide} src={clinic4} alt="Redemption Psychiatry Ketamine Treatment Clinic"></img>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <h2 className={classes.title}>
                                Starting the IV.
                            </h2><br></br>
                            <h5 className={classes.description}>
                                Our specialized ketamine nurses are very experienced in IV placement and will place the IV catheter using a small-gauge needle as painlessly as possible to use for the infusion. Prior to starting the Ketamine drip, a medication such as atropine (or glycopyrrolate) is administered to safely and effectively prevent some of the possible side effects of Ketamine including excessive salivation and nausea.
                            </h5><br></br>
                        </GridItem>
                    </GridContainer>
                </div>
                <div className={classes.skewC}></div>
                <div className={classes.colorBlock}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <h2 className={classes.titleWhite}>
                                During the infusion.
                            </h2><br></br>
                            <h5 className={classes.descriptionWhite}>
                                A few minutes after the infusion begins, you will likely start to experience a peaceful and relaxing state that feels disconnected from your body and the world around you. This is a state of dissociation that may include perceptual disturbances, which most people have a difficult time describing. Some patients describe difficulty perceiving the passage of time, some see colors, some describe interesting feelings in their bodies like tingling, numbness, or increased size of limbs.
                            </h5><br></br>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <img className={classes.rightSide} src={clinic5} alt="Ketamine Infusion Clinic"></img>
                        </GridItem>
                    </GridContainer>
                </div>
                <div className={classes.skewCC}></div>
                <div className={classes.whiteBlock}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <img className={classes.leftSide} src={clinic6} alt="Ketamine Treatment Center"></img>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <h2 className={classes.title}>
                                Getting the most out of treatment.
                            </h2><br></br>
                            <h5 className={classes.description}>
                                The level of dissociation is not always the same from one infusion to another and the benefit of the treatment is not dependent on having an intense experience. Many factors play a role in the patient’s subjective experience during the treatment, which depending on the patient, may include feeling anxious, being sleep deprived, being in pain, and the level of comfort with the procedure. We strongly advise patients to approach Ketamine infusion treatments with the outcome in mind, especially if the temporary feeling of dissociation may make you feel anxious or uncomfortable.
                            </h5><br></br>
                        </GridItem>
                    </GridContainer>
                </div>
                <div className={classes.skewC}></div>
                <div className={classes.colorBlock}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <h2 className={classes.titleWhite}>
                                Assuring safety.
                            </h2><br></br>
                            <h5 className={classes.descriptionWhite}>
                                The nurse will monitor your vital signs during the treatment including heart rate, blood pressure, and oxygen saturation, and following the doctor’s orders for your treatment, adjust the Ketamine drip rate or administer comfort medications to optimize your response and prevent side effects. The doctor can be reached at a moment’s notice if needed.
                            </h5><br></br>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <img className={classes.rightSide} src={clinic7} alt="PTSD treatment in gilbert arizona"></img>
                        </GridItem>
                    </GridContainer>
                </div>
                <div className={classes.skewCC}></div>
                <div className={classes.whiteBlock}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <img className={classes.leftSide} src={clinic8} alt="Depression treatment in chandler arizona"></img>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <h2 className={classes.title}>
                                Dosing the treatment.
                            </h2><br></br>
                            <h5 className={classes.description}>
                                We will start with low-dose infusions at 0.5 mg/kg consistent with accepted protocols that have been developed and researched since the early 2000s. In order to find the most effective dose, we may speed up or slow down the infusion rate and/or raise or lower the dose. These adjustments can be made during an infusion or adjusted for subsequent treatments. You may experience different feelings and sensations from one infusion to the next regardless of whether any changes were made, and this is all very normal. There is no typical sensation a patient should experience in order to have a good effect. Although we may increase a patient’s dose, unlike most medication treatments, a higher dose of Ketamine is not necessarily more effective, which is why the benefits of Ketamine for mood were not apparent over decades of using high doses for anesthesia.
                            </h5><br></br>
                        </GridItem>
                    </GridContainer>
                </div>
                <div className={classes.skewC}></div>
                <div className={classes.colorBlock}>
                    <GridContainer>
                        <GridItem xs={12} sm={12} md={6}>
                            <h2 className={classes.titleWhite}>
                                Following treatment.
                            </h2><br></br>
                            <h5 className={classes.descriptionWhite}>
                                The nurse will remove the IV catheter and allow you to fully recover before you leave. You will need someone to drive you home because although you may feel fine, your reflexes will be slowed and equilibrium may be slightly off, so you will not be safe to drive, care for children, or make important decisions until you’ve had a full night’s sleep to reset. We also have patients who choose to take a cab or an Uber; although this is not preferred, since we feel it would be best to travel with someone you know and trust. The medication will rapidly leave your system as soon as the drip is completed, so there will be no lingering side effects of Ketamine circulating in your blood.
                            </h5><br></br>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                            <img className={classes.rightSide} style={{}} src={clinic9} alt="Anxiety treatment center in gilbert arizona"></img>
                        </GridItem>
                    </GridContainer>
                </div>
                <div className={classes.skewCC}></div>
                <div className={classes.whiteBlock}>
                </div>
                <br></br><br></br>
                <br></br><br></br>
                <Container maxWidth="lg" component="main" className={classes.heroContent}>
                    <Typography component="h3" variant="h3" align="center" color="textPrimary" gutterBottom>
                        Frequently Asked Questions
                     </Typography>
                    <WhatToExpectFAQPanel />
                    <Typography variant="h5" align="center" color="textSecondary" component="h5">
                        Still have questions? Refer to our <a href="/faq-page" className={classes.block}>General FAQ</a> for more information,
                        <br></br>
                        or give us a call/visit and ask our expert staff!
                    </Typography>
                </Container>
                <br></br><br></br>
                <br></br><br></br>
            </div>
            <Footer />
        </div>
    );
}
