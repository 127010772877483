import React from "react";
import ReactTextCollapse from "react-text-collapse";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import GradeIcon from '@material-ui/icons/Grade';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import RepeatOneIcon from '@material-ui/icons/RepeatOne';
import GroupIcon from '@material-ui/icons/Group';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoAreaLanding.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/WhyChooseUsStyle.js";


const useStyles = makeStyles(styles);

export default function WhyChooseUs() {
  const classes = useStyles();
  const TEXT_COLLAPSE_OPTIONS = {
    collapse: false, // default state when component rendered
    collapseText:<div className={classes.iconHover}><h6 className={classes.showMore}> Show More </h6><ExpandMoreIcon> </ExpandMoreIcon></div>, // text to show when collapsed
    expandText:<div className={classes.iconHover}><h6 className={classes.showLess}> Show Less </h6><ExpandLessIcon> </ExpandLessIcon></div>, // text to show when expanded
    minHeight: 75, // component height when closed
    maxHeight: 300, // expanded to
    color: "black",
    textStyle: { // pass the css for the collapseText and expandText here
      color: "#003C8F",
      fontSize: "15px",
      marginLeft: "120px",
    }
  }
 
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Why Choose Redemption?</h2>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="We specialize in psychiatry and treatment-resistant disorders."
              description={<ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
              <div className={classes.description}>
              Doctors specialize for very good reason and off-label treatments should only be prescribed by a specialist. It is essential for a doctor to be trained extensively in a condition in order to provide the best care. Ketamine is not currently the preferred, first-line treatment for any psychiatric disorder and is only FDA approved for use as a general anesthetic, so it must be recommended by a psychiatrist when using it off-label for a mental health disorder. Any other type of doctor recommending Ketamine for depression, PTSD, OCD, or any other psychiatric disorder is practicing outside of their scope. This would be like your family doctor recommending chemotherapy instead of sending you to an oncologist for a cancer diagnosis and letting them recommend treatment.
              </div>
            </ReactTextCollapse>}
              icon={RepeatOneIcon}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="We are one of the largest private psychiatric practices in Arizona."
              description={<ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
              <div className={classes.description}>
              That doesn’t happen overnight and comes from having many happy clients, valued relationships with referral sources, and excellent outcomes. We maintain a high quality of care and attract more and more exceptional mental health providers. This has enabled us to have experts in many areas of psychiatric practice at several locations with some of the most advanced treatments available. No single doctor can be the right person to help all patients, so having several psychiatrists allows us the greatest potential to help.
              </div>
            </ReactTextCollapse>}
              icon={VerifiedUser}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="Our expertise in the use of advanced psychiatric treatments."
              description={<ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
              <div className={classes.description}>
              Advanced psychiatric treatments and interventions encompass a relatively new area of practice that does not have a specialty board. This means that in order to become experts in this area, instead of being able to graduate a specialty fellowship program and take a board exam, doctors must seek out training on their own, attend national conferences, and keep up to date with the research. More importantly, to become an expert in any area of medicine, doctors must use their skills regularly and handle all kinds of unusual situations. To accomplish this, they must have experience in dealing with a wide variety of patients from all demographics with different combinations of medications and medical disorders. At Redemption Psychiatry, we check all these boxes because we have a very large volume practice including one of the largest volume advanced psychiatric treatment departments in Arizona. You can rest assured that you are working with experts.
              </div>
            </ReactTextCollapse>}
              icon={GradeIcon}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="We have many more treatment options in addition to Ketamine."
              description={<ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
              <div className={classes.description}>
              Beware of clinics and any doctors who only provide Ketamine treatment and do not provide the most basic psychiatric treatments such as diagnostic evaluations and medication management. Although these clinics may claim their focus on Ketamine treatment makes them the best qualified to perform the treatment, that is not the case. The fact is, any clinic that only performs costly procedures is profit-driven and will be motivated to get as many patients to undergo Ketamine treatment as possible. Every patient is complex and needs a Ketamine expert who takes a patient-centered approach, which means they must maintain their essential psychiatric skills and should develop a myriad of additional skills to care for treatment-resistant conditions such as Transcranial Magnetic Stimulation, Theta Burst Stimulation, Electroconvulsive Therapy, Deep Brain Stimulation, etc.
              </div>
            </ReactTextCollapse>}
              icon={AllInclusiveIcon}
              iconColor="info"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="We make Ketamine as affordable as possible."
              description={<ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
              <div className={classes.description}>
              Providing the initial infusion at no charge is a commitment we have made to the success of Ketamine treatment and we will do our best to continue this program as long as we have our patients’ support. Payment plans, discount programs, and combination therapies with TMS allow us to help more patients than we possibly could if we expected Ketamine treatment to fund our entire business.
              </div>
            </ReactTextCollapse>}
              icon={MonetizationOnIcon}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <InfoArea
              title="We accept referral's for patients from other clinics/providers."
              description={<ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
              <div className={classes.description}>
              Although we are happy to be your primary pyschiatric health care provider, we understand that finding a pyschiatrist who is the right fit for you can be challenging. Do you already have a doctor you want to continue seeing? We accept outside referrals for your advanced treatments. We can coordinate care! Have your current provider refer you to us for an infusion or advanced treatment, come see us for your treatment and then return to the referring provider you are currently seeing to follow up.
              </div>
            </ReactTextCollapse>}
              icon={GroupIcon}
              iconColor="info"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
