import React, { Component } from 'react';
import { Map, GoogleApiWrapper, InfoWindow, Marker } from 'google-maps-react';
const mapStyles = {
  width: '100%',
  height: '100%',
  borderBottomRightRadius: "6px",
  borderBottomLeftRadius: "6px",
};


export class GoogleMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      offices: [{ lat: 33.5999394, lng: -112.0135042 },
      // {
      //   latitude: 33.484443, longitude: -112.071474,
      //   name: <React.Fragment>Redemption Psychiatry Ketamine Clinic <br /> Phoenix</React.Fragment>,
      //   address: <React.Fragment>202 E Earll Drive, Suite 160 <br /> Phoenix, AZ 85012</React.Fragment>,
      //   directions: "http://maps.apple.com/?q=Redemption+Psychiatry+Phoenix&sll=33.484443,-112.071474&z=10&t=s"
      // },
      {
        latitude: 33.5999394, longitude: -112.0135042,
        name: <React.Fragment>Redemption Psychiatry Ketamine Clinic <br /> Scottsdale</React.Fragment>,
        address: <React.Fragment>12424 North 32nd Street, Suite 101 <br /> Phoenix, AZ 85032</React.Fragment>,
        directions: "http://maps.apple.com/?q=12424+North+32nd+Street&sll=33.5999394,-112.0135042&z=10&t=s"
      }],
      showingInfoWindow: false,  //Hides or the shows the infoWindow
      activeMarker: {},          //Shows the active marker upon click
      selectedPlace: {}          //Shows the infoWindow to the selected place upon a marker
    }
  }
  // Phoenix:   33.484443, -112.071474 -- ChIJ0_5OgGASK4cROzcGDQFGd4w -- https://www.google.com/maps/search/?api=1&query=33.484443,-112.071474&query_place_id=ChIJ0_5OgGASK4cROzcGDQFGd4w
  // Arrowhead: 33.643983, -112.230209 -- ChIJncx1YzloK4cR89LYm-DDBE4 -- https://www.google.com/maps/search/?api=1&query=33.643983,-112.230209&query_place_id=ChIJncx1YzloK4cR89LYm-DDBE4


  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });

  onClose = props => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  };

  displayMarkers = () => {
    return this.state.offices.map((office, index) => {
      return <Marker key={index} id={index} position={{
        lat: office.latitude,
        lng: office.longitude,
      }}
        name={office.name}
        address={office.address}
        directions={office.directions}
        onClick={this.onMarkerClick} />
    })
  }

  render() {
    return (
      <Map
        google={this.props.google}
        zoom={11}
        style={mapStyles}
        initialCenter={{ lat: 33.5519248, lng: -111.9993677 }}
        // {{ lat: 33.5999394, lng: -112.0135042 }}
      >
        {this.displayMarkers()}
        <InfoWindow
          marker={this.state.activeMarker}
          visible={this.state.showingInfoWindow}
          onClose={this.onClose}
        >
          <div>
            <h4 style={{ textAlign: "left" }}>{this.state.selectedPlace.name}</h4>
            <hr></hr>
            <p>{this.state.selectedPlace.address}</p>
            <button><a href={this.state.selectedPlace.directions}>Get Directions!</a></button>
          </div>
        </InfoWindow>
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_MAP_KEY
})(GoogleMap);


