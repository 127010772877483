import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: "#E5E5E5",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    color: "#FFFFFF",
    expansionIndicator: "#FFFFFF",
  },
  summary: {
    backgroundColor: "#2B4E90",
  },
  typographyFAQ: {
    textAlign: "left",
  }
}));

export default function WhatToExpectFAQPanel() {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.root}>
        <ExpansionPanel>
          <ExpansionPanelSummary
            className={classes.summary}
            expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>What is the frequency and duration of treatment?</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.typographyFAQ}>
            <Typography>
              The treatment generally involves an initial round of six infusions and continued maintenance infusions as needed every one to three months until long-term remission is sustained. The ketamine dosage and the IV drip rate are determined using factors such as the patient’s weight, medical conditions, and response to treatment, but in general, the infusion takes 40-60 minutes and another 20-30 minutes to recover.
          </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel>
          <ExpansionPanelSummary
            className={classes.summary}
            expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>When can patients expect to see results? </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.typographyFAQ}>
            <Typography>
              Unlike most psychiatric treatments that take 4-6 weeks to see improvement, some patients experience relief with Ketamine within an hour of their first treatment and most will see the results within a day.
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel>
          <ExpansionPanelSummary
            className={classes.summary}
            expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>How long do the results last?</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.typographyFAQ}>
            <Typography>
              The effects of one infusion can last for several days to a few weeks, which can provide relief during the acute phase of an illness and prevent negative outcomes. However, the effects of a series of six or more infusions delivered over 2-4 weeks will build on each other to promote more long-lasting effects over several months. When a treatment series is effective, patients may find they can maintain the benefits by having maintenance infusions every 1-3 months.
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel>
          <ExpansionPanelSummary
            className={classes.summary}
            expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>How many infusions will I need?</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.typographyFAQ}>
            <Typography>
              A series of six infusions followed by maintenance treatments every 1 to 3 months provides greater and longer-lasting results since each infusion builds on the last. Depending on your response and the duration of the Ketamine effect, we will gradually extend the time between treatments to taper treatments over time. The total number and frequency of treatments will vary from patient to patient.
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel>
          <ExpansionPanelSummary
            className={classes.summary}
            expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>Is there an age limit for Ketamine?</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.typographyFAQ}>
            <Typography>
              There is not a formal age limitation. We recognize that depression is not something that only happens to adults. Dr. Friday is a Board-Certified Psychiatrist and a Board Certified Child and Adolescent Psychiatrist. In addition to the adult population, he possesses a comfort level treating patients under the age of 18 as well. However, treatment will ultimately be decided on a case by case basis.
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel>
          <ExpansionPanelSummary
            className={classes.summary}
            expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>Are there medications you can’t take with Ketamine?</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.typographyFAQ}>
            <Typography>
              While Ketamine is considered a very safe medication overall, please inform us of any and all medications you are taking as we believe certain medications may inhibit the full effectiveness of the ketamine infusion treatment.
              <br /><br /><strong>
              •	Lamictal (generic name Lamotrigine):</strong> Patients should allow 6 hours between taking Lamictal and the start of their infusion. They should wait 6 hours after their infusion before resuming Lamicatal.
              <br /><br /><strong>
              •	Benzodiazepines such as Xanax(alprazolam), Ativan(Lorazepam), Valium(Diazepam) and Klonopin(Clonazepam), etc. </strong>While these medications are prefectly safe to take during an infusion, but we want to ensure the best possible chances for your success, so we may ask you skip a dose 12 hours before the start of your infusion and wait until 6 hours after before resuming your benzodiazepine.
              <br /><br /><strong>
              •	SSRI and tricyclics </strong>do not interfere with Ketamine. There is no need to stop them.
              <br /><br />
              •	If you are taking <strong> opiates, muscle relaxants, or anti-inflammatories,</strong> there is no need to adjust your dose.
              <br /><br />
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
      <br></br><br></br><br></br>
    </div>
  );
}