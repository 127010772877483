import { container, title } from "assets/jss/material-kit-react.js";

import imagesStyle from "assets/jss/material-kit-react/imagesStyles.js";

const profilePageStyle = theme => ({
    body: {
        margin: "0px",
    },
    colorBlock: {
        background: "#003C8F",
        width: "100%",
        padding: "7% 2% 1% 5%",
        color: "#fff",
        justifyContent: "space-between",
        left: "0"
    },
    whiteBlock: {
        background: "#fff",
        width: "100%",
        padding: "7% 2% 1% 5%",
        color: "#3C4858",
        justifyContent: "space-between",
    },
    h2: {
        textAlign: "center",
        fontSize: "80px",
        fontFamily: "'wire one', serif",
        fontWeight: "normal",
    },
    h5: {
        fontSize: "20px",
        fontFamily: "'Raleway', serif",
    },

    /*------------------*/
    /*  SKEW CLOCKWISE  */
    /*------------------*/
    skewCC: {
        width: "100%",
        height: "100px",
        position: "absolute",
        left: "0px",
        background: 'linear-gradient(to right bottom, #003C8F 49%, #fff 50%), linear-gradient(-50deg, #ffffff 16px, #000 0)',
    },

    /*-------------------------*/
    /* SKEW COUNTER CLOCKWISE  */
    /*-------------------------*/
    skewC: {
        width: "100%",
        height: "100px",
        position: "absolute",
        left: "0px",
        background: 'linear-gradient(to left bottom, #fff 49%, #003C8F 50%)',
    },
    container,
    leftSide: {
        height: "80%",
        width: "80%",
        clipPath: "polygon(0% 10%, 100% 0%, 100% 100%, 0% 90%)",
        [theme.breakpoints.down("sm")]: {
            marginTop: "60px",
            clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
            marginLeft: "40px"
          }
    },
    rightSide: {
        height: "80%",
        width: "80%",
        right: "0",
        clipPath: "polygon(0% 0%, 100% 10%, 100% 90%, 0% 100%)",
        [theme.breakpoints.down("sm")]: {
            marginTop: "60px",
            clipPath: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
            marginLeft: "40px"
          }
    },
    profile: {
        textAlign: "center",
        "& img": {
            maxWidth: "200px",
            width: "100%",
            margin: "0 auto",
            transform: "translate3d(0, -35%, 0)"
        }
    },
    imgTab: {
        width: "500px",
        height: "500px",
    },
    description: {
        margin: "0 auto",
        textAlign: "center !important",
        justifyContent: "center"
    },
    descriptionWhite: {
        margin: "0 auto",
        color: "#FFFFFF",
        border: "2px",
        borderColor: "#999",
        textAlign: "center !important",
        justifyContent: "center"
    },
    name: {
        marginTop: "-80px"
    },
    ...imagesStyle,
    main: {
        background: "#FFFFFF",
        position: "relative",
        zIndex: "3"
    },
    mainRaised: {
        margin: "-60px 30px 0px",
        borderRadius: "6px",
        boxShadow:
            "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
    },
    title: {
        ...title,
        margin: "0 auto",
        textAlign: "center !important",
        justifyContent: "center",
        [theme.breakpoints.down("sm")]: {
            marginTop: "70px",
          }
    },
    titleWhite: {
        ...title,
        margin: "0 auto",
        textAlign: "center !important",
        justifyContent: "center",
        color: "#FFFFFF",
        // textShadow: "-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black",
        [theme.breakpoints.down("sm")]: {
            marginTop: "70px",
          }
    },
    socials: {
        marginTop: "0",
        width: "100%",
        transform: "none",
        left: "0",
        top: "0",
        height: "100%",
        lineHeight: "41px",
        fontSize: "20px",
        color: "#999"
    },
    navWrapper: {
        margin: "20px auto 50px auto",
        textAlign: "center"
    },
    content: {
        justifyContent: "space-between",
        margin: "0",
        width: "100%",
        minWidth: "100%",
        flexGrow: "1",
        left: "0",
        paddingRight: "150px"
    }
});

export default profilePageStyle;
