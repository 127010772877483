import React from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js"
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import profile from "assets/img/RLogohalfsize.png";
import CustomTabs from "components/CustomTabs/CustomTabs.js";
//sections
import WhatToExpectFAQPanel from "components/FAQs/WhatToExpectFAQ.js";
//images
import OCDAlgorithm from "../../assets/img/Treatments/OCD-Algorithm.jpg";
import OCDAlgorithm2 from "../../assets/img/Treatments/OCD-Algorithm2.jpg";
import DepressionAlgorithm from "../../assets/img/Treatments/DepressionAlgorithm.jpg";
import DepressionAlgorithm2 from "../../assets/img/Treatments/DepressionAlgorithm2.jpg";
import MedsDontWork from "../../assets/img/Treatments/MedsDontWork.png";
import KetVSMeds from "../../assets/img/Treatments/KetVSMeds.jfif";

import styles from "assets/jss/material-kit-react/views/profilePage.js";
const useStyles = makeStyles(styles);

export default function PricingPage(props) {
    const classes = useStyles();
    const { ...rest } = props;
    const imageClasses = classNames(
        classes.imgRounded,
        classes.imgFluid
    );
    return (
        <div>
            <HelmetProvider>
                <Helmet>
                    <title>Redemption Psychiatry | Treatment for Depression, PTSD, Anxiety, Chronic Pain, OCD and Other Conditions</title>
                    <meta name="description" content="According to the National Institute of Mental Health, Ketamine works within hours to improve mood. Our Treatments for Depression, Anxiety, PTSD, Chronic Pain and Other Conditions offer lasting results to get you back to living your best life. Learn more about how Ketamine Infusion Therapy could help you!" />
                    <meta property="og:locale" content="en_US" />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content="Redemption Psychiatry | Treatment for Depression, PTSD, Anxiety, Chronic Pain, OCD and Other Conditions" />
                    <meta property="og:description" content="According to the National Institute of Mental Health, Ketamine works within hours to improve mood. Our Treatments for Depression, Anxiety, PTSD, Chronic Pain and Other Conditions offer lasting results to get you back to living your best life. Learn more about how Ketamine Infusion Therapy could help you!" />
                    <meta property="og:url" content="https://www.rpsychinfusions.com/diagnosis-page" />
                    <meta property="og:site_name" content="Redemption Psychiatry Ketamine Infusions" />
                    <meta property="og:image" content="https://my.datasphere.com/files/mydatasphere/styles/full_640/public/business/images/0419/1555625884_12657239_RedemptionPsychiatry_500x500.png?itok=Qrowvv_y" />
                    <meta property="og:image:secure_url" content="https://my.datasphere.com/files/mydatasphere/styles/full_640/public/business/images/0419/1555625884_12657239_RedemptionPsychiatry_500x500.png?itok=Qrowvv_y" />
                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:title" content="Redemption Psychiatry | Treatment for Depression, PTSD, Anxiety, Chronic Pain, OCD and Other Conditions" />
                    <meta name="twitter:description" content="According to the National Institute of Mental Health, Ketamine works within hours to improve mood. Our Treatments for Depression, Anxiety, PTSD, Chronic Pain and Other Conditions offer lasting results to get you back to living your best life. Learn more about how Ketamine Infusion Therapy could help you!" />
                    <meta name="twitter:image" content="https://my.datasphere.com/files/mydatasphere/styles/full_640/public/business/images/0419/1555625884_12657239_RedemptionPsychiatry_500x500.png?itok=Qrowvv_y" />
                    <link rel='dns-prefetch' href='//www.google.com' />
                    <link rel="canonical" href="https://www.rpsychinfusions.com/diagnosis-page" />
                </Helmet>
            </HelmetProvider>
            <Header
                color="transparent"
                rightLinks={<HeaderLinks />}
                fixed
                changeColorOnScroll={{
                    height: 200,
                    color: "white"
                }}
                {...rest}
            />
            <Parallax small filter image={require("assets/img/selective-focus-photography-of-hand-1654698.jpg")} />
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.backgroundTreatments}>
                    <div className={classes.profilePrice}>
                        <div>
                            <img src={profile} alt="Redemption Psychiatry Ketamine Therapy Center" className={imageClasses} />
                        </div>
                        <div className={classes.namePrice}>
                            <h2 className={classes.title}>Treatments</h2>
                            <h5 className={classes.descriptionBlack}>Find out more about how Ketamine may help with a specific diagnosis</h5>
                            <br></br>
                        </div>
                        <div className={classes.container}>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <CustomTabs
                                        headerColor="primary"
                                        centered
                                        tabs={[
                                            {
                                                tabName: "Depression",
                                                tabContent: (
                                                    <div>
                                                        <Typography component="h4" variant="h4" align="left" color="textPrimary" gutterBottom>
                                                            How effective is Ketamine for depression?
                                                            </Typography>
                                                        <img
                                                            style={{ maxWidth: "500px", transform: "translate3d(0, 0, 0)", height: "100%", width: "100%", position: "block" }}
                                                            src={DepressionAlgorithm2}
                                                            alt="http://www.indianjpsychiatry.org/viewimage.asp?img=IndianJPsychiatry_2017_59_5_74_196976_f2.jpg">
                                                        </img>
                                                        <Typography align="left" color="textPrimary" component="p">
                                                            For depression, including severe and treatment-resistant episodes, studies demonstrate that Ketamine infusions are about 70% effective at significantly reducing depression symptoms. It is important for these studies to exclude patients who are using other treatments including medication or psychotherapy to evaluate the effectiveness of Ketamine treatment by itself, but in practice, treatments are often given in combination to achieve the best outcomes. Most of our patients use several different treatments in combination including antidepressant medication, therapy, and transcranial magnetic stimulation (TMS). This multifaceted treatment approach explains why we are able to significantly reduce depression symptoms in 80-90% of our patients.
                                                            <br /><br />
                                                            About 50% of patients will know whether Ketamine is a good option for them after the first infusion, but another 20% respond following the second infusion, and 10% after the third infusion. If a patient does not see improvement after the third infusion, we do not recommend continuing treatment as the likelihood of responding after four or more infusions is too low to warrant further trials. However, when patients have lived with depression for many years, it can be difficult to perceive positive changes and family and friends often identify changes before the patient does.
                                                        </Typography><br></br>
                                                        <img
                                                            style={{ maxWidth: "500px", transform: "translate3d(0, 0, 0)", height: "100%", width: "100%", position: "block" }}
                                                            src={DepressionAlgorithm}
                                                            alt="http://www.indianjpsychiatry.org/viewimage.asp?img=IndianJPsychiatry_2017_59_5_74_196976_f2.jpg">
                                                        </img>
                                                    </div>
                                                )
                                            },
                                            {
                                                tabName: "Anxiety",
                                                tabContent: (
                                                    <div>
                                                        <Typography component="h4" variant="h4" align="left" color="textPrimary" gutterBottom>
                                                            How effective is Ketamine for Anxiety?
                                                            </Typography>
                                                        <Typography variant="p" align="left" color="textPrimary" component="p">
                                                            “Ketamine can play an important role in the treatment of anxiety disorders,” according to Prakash Masand, MD, co-founder, chairman, and CEO of Centers of Psychiatric Excellence (COPE) and adjunct professor at the Academic Medicine Education Institute, Duke-National University of Singapore Medical School (Duke-NUS) (https://www.copepsychiatry.com).
                                                            <br></br><br></br>
                                                            Many patients with anxiety are treated with traditional antidepressants and a variety of additional medications including antihistamines, beta-blockers, and benzodiazepines. These additional medications can be sedating, and benzodiazepines can have a negative effect on memory and cognition. As many as 30% to 40% of patients will not achieve remission despite 3 or 4 different traditional medications and evidence-based nonpharmacologic therapies such as cognitive behavioral therapy (CBT) or mentalization-based therapy (MBT) (https://www.psychiatryadvisor.com/home/topics/anxiety/ketamine-a-promising-novel-therapy-for-anxiety-and-ptsd/).
                                                            <br></br><br></br>
                                                            Ketamine infusions have proven to be a rapidly effective treatment modality for a variety of anxiety disorders such as generalized anxiety disorder (GAD), obsessive-compulsive disorder (OCD), social anxiety disorder (SAD), and post-traumatic stress disorder (PTSD).
                                                            <br></br><br></br>
                                                            Most anxiety medications work primarily on the histamine and monoamine systems in the brain. Ketamine’s NMDA-receptor antagonism is linked to potentiation of glutamate transmission and increased brain-derived neurotrophic factor (BDNF) levels associated with antidepressant and anxiolytic properties. Ketamine stimulates cell growth in the brain promoting neuronal growth and connectivity, neuroplasticity, and a rapid anxiolytic effect.
                                                            <br></br><br></br>
                                                        </Typography><br></br>
                                                        <img
                                                            style={{ maxWidth: "900px", transform: "translate3d(0, 0, 0)", height: "100%", width: "100%", position: "block" }}
                                                            src={MedsDontWork}
                                                            alt="http://www.indianjpsychiatry.org/viewimage.asp?img=IndianJPsychiatry_2017_59_5_74_196976_f2.jpg">
                                                        </img>
                                                    </div>
                                                )
                                            },
                                            {
                                                tabName: "PTSD",
                                                tabContent: (
                                                    <div>
                                                        <Typography component="h4" variant="h4" align="left" color="textPrimary" gutterBottom>
                                                            How effective is Ketamine for PTSD?
                                                            </Typography>
                                                        <Typography variant="p" align="left" color="textPrimary" component="p">
                                                        Posttraumatic stress disorder (PTSD) is a chronic stress response initiated by exposure to a traumatic event and characterized by persistent reexperiencing (nightmares/flashbacks), hyperarousal (hypervigilance, increased startle response), and avoidance of thoughts and reminders of the event. Chronic stress taxes the neurological system, causes neural atrophy, and reduces synaptic connectivity in the cortical and limbic circuits of the brain, which are responsible for mood, behavior, and cognition [1]. Traditional medications used to treat PTSD (selective serotonin reuptake inhibitors [SSRIs], serotonin norepinephrine reuptake inhibitors [SNRIs], and alpha agonists/antagonists) often produce significant levels of nonresponse and residual symptoms are common.
                                                                 <br /><br />
                                                                 There is evidence that PTSD may be a “synaptic disconnection syndrome” and Ketamine is a rapid-acting treatment that repairs and restores synaptic connectivity in the brain. Ketamine improves the quality of signaling via glutamate synapses by increasing the availability of glutamate and brain-derived neurotropic factor (BDNF) and stimulating dendritic growth and synaptic connectivity [1]. This enhanced signaling reduces the effects of chronic stress on the brain and body.
                                                            </Typography><br></br>
                                                        <Typography component="h4" variant="h4" align="left" color="textPrimary" gutterBottom>
                                                            What is the frequency and duration of treatment?
                                                             </Typography>
                                                        <Typography variant="p" align="left" color="textPrimary" component="p">
                                                            The treatment generally involves an initial round of six infusions and continued maintenance infusions as needed every one to three months until long-term remission is sustained. The ketamine dosage and the IV drip rate are determined using factors such as the patient’s weight, medical conditions, and response to treatment, but in general, the infusion takes 40-60 minutes and another 20-30 minutes to recover.
                                                            <br /><br />
                                                            <hr />
                                                            [1] Krystal, J.H., Abdallah, C.G., Averill, L.A. et al. Synaptic Loss and the Pathophysiology of PTSD: Implications for Ketamine as a Prototype Novel Therapeutic. Curr Psychiatry Rep 19, 74 (2017). <a href="https://doi.org/10.1007/s11920-017-0829-z">https://doi.org/10.1007/s11920-017-0829-z</a>
                                                            </Typography>
                                                    </div>
                                                )
                                            },
                                            {
                                                tabName: "OCD",
                                                tabContent: (
                                                    <div>
                                                        <Typography component="h4" variant="h4" align="left" color="textPrimary" gutterBottom>
                                                            How effective is Ketamine for Obsessive Compulsive Disorder (OCD)?
                                                            </Typography>
                                                        <Typography variant="p" align="left" color="textPrimary" component="p">
                                                            Increasing evidence indicates that abnormal levels of the neurotransmitter glutamate contribute to OCD and may be a fruitful target for new therapies. Ketamine’s rapid antidepressant effect suggests that similar anti-obsessional effects are a real possibility, since the disorders frequently occur together and problems with glutamate appear to be associated with both. <br></br><br></br>
                                                            The rapid symptom relief seen from ketamine is substantially different from symptom relief in current first-line treatments for OCD. With current treatments, patients wait up to six to 10 weeks to see clinically meaningful improvement and may only experience a 40% to 60% reduction in symptoms, according to lead author Carolyn Rodriguez, MD, PhD, Assistant Professor at Columbia University in New York.
                                                            <br></br><br></br>
                                                            <Typography component="h4" variant="h4" align="left" color="textPrimary" gutterBottom>
                                                                OCD decision tree's to help determine appropriate treatment.
                                                            </Typography><hr></hr>
                                                            Figure 1: Treatment algorithm for treating a patient with OCD. *First line treatment chosen based on feasibility and severity of illness, #CBT/BT- Cognitive behavior therapy/Behavior therapy, @SSRI - Selective serotonin reuptake inhibitor, %rTMS-repetitive transcranial magnetic stimulation, $ - tDCS- transcranial direct current stimulation. ** Preferred for severe OCD
                                                            </Typography><br></br>
                                                        <img style={{ maxWidth: "600px", transform: "translate3d(0, 0, 0)", height: "800px", width: "600px", position: "block" }} src={OCDAlgorithm} alt="http://www.indianjpsychiatry.org/articles/2017/59/5/images/IndianJPsychiatry_2017_59_5_74_196976_f1.jpg"></img><br></br><hr></hr>
                                                        <Typography variant="p" align="left" color="textPrimary" component="p">
                                                            Figure 2: Strategies for non-responders to SSRIs. SSRI-Selective serotonin reuptake inhibitors, CBT/BT-Cognitive behavior therapy/behavior therapy, rTMS- repetitive transcranial magnetic stimulation
                                                            </Typography><br></br>
                                                        <img
                                                            style={{ maxWidth: "600px", transform: "translate3d(0, 0, 0)", height: "800px", width: "600px", position: "block" }}
                                                            src={OCDAlgorithm2}
                                                            alt="http://www.indianjpsychiatry.org/viewimage.asp?img=IndianJPsychiatry_2017_59_5_74_196976_f2.jpg">
                                                        </img>
                                                    </div>
                                                )
                                            },
                                            {
                                                tabName: "Suicidality",
                                                tabContent: (
                                                    <div>
                                                        <Typography align="center">
                                                            <strong>If You or Someone You Know is In Crisis
                                                                <br></br>
                                                                Call the National Suicide Prevention Lifeline (Lifeline) at 1-800-273-TALK (8255)
                                                               <br></br>
                                                                Or
                                                                <br></br>
                                                                Text the Crisis Text Line (text HELLO to 741741).
                                                                <br></br>
                                                                Both services are free and available 24 hours a day, seven days a week.
                                                                <br></br><hr></hr>
                                                                Veterans, their family members and friends may contact the Department of Veteran’s Affairs through a confidential toll-free hotline, online chat, or text messaging service:
                                                                <br></br>
                                                                Dial 1-800-273-8255 and Press 1
                                                                <br></br>
                                                                Send a text message to 838255
                                                                <br></br>
                                                                Start a confidential online chat session at <a href="https://www.veteranscrisisline.net/get-help/chat">veteranscrisisline.net/get-help/chat.</a>
                                                            </strong><hr></hr>
                                                        </Typography>
                                                        <Typography component="h4" variant="h4" align="center" color="textPrimary" gutterBottom>
                                                            How effective is Ketamine for Suicidality?
                                                            </Typography>
                                                        <Typography variant="p" align="left" color="textPrimary" component="p">
                                                            Suicidal ideation is complex. It does not have a singular cause. However, undiagnosed or untreated depression is one of the most common mental health disorders associated with suicide.
                                                            </Typography><br></br>
                                                        <img
                                                            style={{ maxWidth: "900px", transform: "translate3d(0, 0, 0)", height: "100%", width: "100%", position: "block" }}
                                                            src={KetVSMeds}
                                                            alt="http://www.indianjpsychiatry.org/viewimage.asp?img=IndianJPsychiatry_2017_59_5_74_196976_f2.jpg">
                                                        </img>
                                                    </div>
                                                )
                                            }
                                        ]}
                                    />
                                </GridItem>
                            </GridContainer>
                            <br></br><br></br><br></br>
                            <Container maxWidth="lg" component="main" className={classes.heroContent}>
                                <Typography component="h3" variant="h3" align="center" color="textPrimary" gutterBottom>
                                    Frequently Asked Questions
                            </Typography>
                                <WhatToExpectFAQPanel />
                                <h5 className={classes.descriptionBlack}>
                                    Still have questions? Refer to our <a href="/faq-page" className={classes.block}>General FAQ</a> for more information,
                                <br></br>
                                    or give us a call/visit and ask our expert staff!
                            </h5>
                            </Container>
                            <br></br><br></br>
                            <br></br><br></br>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
