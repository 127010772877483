/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// material-ui core components
import { List, ListItem, Tooltip, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
//styles AKA css
import styles from "assets/jss/material-kit-react/components/footerStyle.js";
//sections

const useStyles = makeStyles(styles);

export default function Footer(props) {
  const classes = useStyles();
  const { whiteFont } = props;
  const footerClasses = classNames({
    [classes.footer]: true,
    [classes.footerWhiteFont]: whiteFont
  });
  const aClasses = classNames({
    [classes.a]: true,
    [classes.footerWhiteFont]: whiteFont
  });

  return (
    <footer className={footerClasses}>
      <div className={classes.container}>
        <div className={classes.center}>
          <List className={classes.list}>
            <ListItem className={classes.inlineBlock}>
              <a
                href="https://workforcenow.adp.com/mascsr/default/mdf/recruitment/recruitment.html?cid=d7a56439-403d-4804-b913-8cf397a9df48&ccId=19000101_000001&type=JS&lang=en_US"
                className={classes.block}
                target="_blank"
              >
                Employment Opportunities
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a
                href="/contact-page"
                className={classes.block}
              >
                Contact Us
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a
                href="/privacypolicy"
                className={classes.block}
              >
                Privacy Policy
              </a>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <a
                href="/disclaimer"
                className={classes.block}
              >
                Disclaimer
              </a>
            </ListItem>
            <br></br>
            <ListItem className={classes.inlineBlock}>
              <Tooltip
                id="instagram-facebook"
                title="Follow us on facebook"
                placement={window.innerWidth > 959 ? "top" : "left"}
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  color="inherit"
                  href="https://www.facebook.com/RedemptionPsychiatry"
                  target="_blank"
                  className={classes.navLink}
                >
                  <i className={classes.socialIcons + " fab fa-facebook"} />
                </Button>
              </Tooltip>
            </ListItem>
            <ListItem className={classes.inlineBlock}>
              <Tooltip
                id="instagram-twitter"
                title="Follow us on twitter"
                placement={window.innerWidth > 959 ? "top" : "left"}
                classes={{ tooltip: classes.tooltip }}
              >
                <Button
                  href="https://twitter.com/redemptionpsych"
                  target="_blank"
                  color="inherit"
                  className={classes.navLink}
                >
                  <i className={classes.socialIcons + " fab fa-twitter"} />
                </Button>
              </Tooltip>
            </ListItem>
          </List><br></br>
          <div style={{ textAlign: "center" }}>
          <a style={{color: "black"}} href="tel:480-576-8666">(480) 576-8666</a> • Fax: (888) 979-8197<br></br>
            Call for appointments Monday-Friday 8am to 5pm<br></br>
            Appointments may be outside call hours</div><br></br>

          <div style={{ textAlign: "center" }}>
            &copy; {1900 + new Date().getYear()} Redemption Psychiatry | website made by Nicholas Farrell
        </div>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {
  whiteFont: PropTypes.bool
};
