import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ReactTextCollapse from "react-text-collapse";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';


const useStyles = makeStyles(theme => ({
    typography: {
        padding: theme.spacing(2),
        maxWidth: "800px",
    },
    textCenter: {
        textAlign: "center"
    },
    buttonMargin: {
        margin: "5px",
    },
    title: {
        fontWeight: "600",
    },
    showMore: {
        display: "inline",
        position: "relative",
        top: "-7px",
    },
    showLess: {
        display: "inline",
        position: "relative",
        top: "-7px",
    },
    iconHover: {
        "&:hover": {
            color: "ed1212",
            cursor: "pointer",
        }
    }
}));


export default function Spravato() {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const [anchorEl3, setAnchorEl3] = React.useState(null);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };
    const handleClick2 = event => {
        setAnchorEl2(event.currentTarget);
    };
    const handleClick3 = event => {
        setAnchorEl3(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setAnchorEl2(null);
        setAnchorEl3(null);
    };
    const open = Boolean(anchorEl);
    const open2 = Boolean(anchorEl2);
    const open3 = Boolean(anchorEl3);
    const id = open ? 'simple-popover' : undefined;
    const id2 = open ? 'simple-popover' : undefined;
    const id3 = open ? 'simple-popover' : undefined;

    const TEXT_COLLAPSE_OPTIONS = {
        collapse: false, // default state when component rendered
        collapseText: <div className={classes.iconHover}><h6 className={classes.showMore}> Show More </h6><ExpandMoreIcon> </ExpandMoreIcon></div>, // text to show when collapsed
        expandText: <div className={classes.iconHover}><h6 className={classes.showLess}> Show Less </h6><ExpandLessIcon> </ExpandLessIcon></div>, // text to show when expanded
        minHeight: 0, // component height when closed
        maxHeight: 300, // expanded to
        color: "black",
        textStyle: { // pass the css for the collapseText and expandText here
            color: "#003C8F",
            fontSize: "15px",
        }
    }
    return (
        <div>
            <div className={classes.title}>
                1.	Spravoto is repurposed Ketamine.
                </div>
            <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                <div className={classes.description}>
                    Pharmaceutical companies use a technique of “repurposing” in order to profit from existing FDA-approved medications as if they were new treatments even though they are already generic. In order to do this, they need to change something about the drug to make it “significantly” different, such as changing the route of administration, dosing, and/or chemical composition. In the case of Spravato, they did all three.
                    <br /><br />
                    <div>
                        <div className={classes.textCenter}>
                            <Button className={classes.buttonMargin} aria-describedby={id} variant="contained" color="primary" onClick={handleClick}>
                                Route change
                            </Button>
                            <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <Typography component="div" className={classes.typography}> Ketamine delivery was changed from an IV formulation to a nasal spray. Unfortunately, this is not the most effective method of Ketamine administration because a significant amount of the medication ends up being swallowed, it is not as effective at symptom reduction, it requires more frequent dosing, there is no control over medication delivery, it has a higher rate of side effects, and there is greater risk of improper administration. An example of FDA approval for a route of administration change is Ritalin (methylphenidate), which is administered orally but converting it to a transdermal patch allowed methylphenidate to be repurposed as Daytrana.</Typography>
                            </Popover>
                            <Button className={classes.buttonMargin} aria-describedby={id2} variant="contained" color="primary" onClick={handleClick2}>
                                Dosing change
                            </Button>
                            <Popover
                                id={id2}
                                open={open2}
                                anchorEl={anchorEl2}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <Typography component="div" className={classes.typography}> There are only two levels of Spravato dosing. It is recommended to try the lower dosing level first and then if it is not effective, the dose can be doubled. IV ketamine is dosed based on weight, medical conditions, and response to treatment, so dosing can be tailored to the patient. An example of FDA approval for a dosage change is Revatio (sildenafil), which was approved for pulmonary hypertension at 20mg increments, but when it was found to help with erectile dysfunction, it was successfully repurposed as Viagra (sildenafil) by changing the dose to 25mg increments.</Typography>
                            </Popover>
                            <Button className={classes.buttonMargin} aria-describedby={id3} variant="contained" color="primary" onClick={handleClick3}>
                                Chemical change
                            </Button>
                            <Popover
                                id={id3}
                                open={open3}
                                anchorEl={anchorEl3}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <Typography component="div" className={classes.typography}>It is what is called an isomer of Ketamine, which is composed of a 50/50 mixture of esketamine and arketamine. Most chemicals have isomers, which have exactly the same number and composition of molecules and are only oriented differently, like right and left hands. Basically, when a patient gets a Ketamine infusion, half of the treatment is already esketamine, but it is afforded a patent as if it were a new discovery. An example of FDA approval for a similar chemical change is Celexa (citalopram), which was approved for depression in 1998, and then the left-handed isomer, Lexapro (escitalopram), was subsequently repurposed in 2002.</Typography>
                            </Popover>

                        </div>
                        <div className={classes.textCenter}>
                            <Typography component="div">
                                Tap or click one of the buttons above to learn more!
                            </Typography>
                        </div>
                    </div>
                </div>
            </ReactTextCollapse>
            <br /> <br />
            <div className={classes.title}>
                2.	Approved only for Treatment-Resistant Depression.
                </div>
            <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                <div className={classes.description}>
                    Despite all the research that shows the benefits of Ketamine for so many different disorders, Spravato was only approved for treatment-resistant depression after 2 failed antidepressants. This is similar to the way Transcranial Magnetic Stimulation (TMS) was FDA approved in 2008 for treatment-resistant depression after only 1 failed antidepressant, and TMS has a greater response rate and long-term remission rate than Spravato. However, it took 8 years before TMS was covered by a majority of insurances, and not after 1 failed antidepressant as indicated by the FDA. Patients must fail 3-4 medications and a course of psychotherapy before will insurance cover TMS. We anticipate the same progression towards coverage to occur with Spravato. After several years of deliberation, insurances will likely cover Spravato, but it probably will be after at least 3-4 failed antidepressants and a course of psychotherapy.
              </div>
            </ReactTextCollapse>
            <br /> <br />
            <div className={classes.title}>
                3.	Very expensive compared to Ketamine.
                </div>
            <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                <div className={classes.description}>
                    Because Ketamine has been generic for so many years and is still used so frequently, it is only a fraction of the cost of Spravato. Depending on the dose and frequency of treatment, the cost of Spravato is approximately 1000 times the cost of IV Ketamine. Even if an insurance decides to cover Spravato, patients may have to pay an extremely high deductible or co-insurance and the cost of administration and observation will be similar.
              </div>
            </ReactTextCollapse>
            <br /> <br />
            <div className={classes.title}>
                4.	Insurance coverage is still evolving.
                </div>
            <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                <div className={classes.description}>
                    Unlike new “routine” medication approvals, Spravato is not routinely obtained or administered and the FDA has imposed special requirements, so there is just no roadmap for implementation. In order to even prescribe Spravato, a doctor must enroll in a Risk Evaluation and Mitigation Strategy (REMS) program. After the doctor prescribes Spravato and insurance approves (or the patient pays cash $590-$885 per use), Spravato has to be delivered to the doctor’s office from a specialty pharmacy, administered by the patient under supervision, and patients must be observed by qualified staff for 2 hours before they can be driven home. No other medication has ever been approved to be used in the same way, so it is understandable that insurance companies can’t agree on how to get the medication to patients.
              </div>
            </ReactTextCollapse>
            <br /> <br />
            <div className={classes.title}>
                5.	No billing codes are available for administration.
                </div>
            <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                <div className={classes.description}>
                    Patients who have served in the military or law enforcement are at higher risk for PTSD, depression, and other disorders as a direct result of their service protecting all Americans. At Redemption Psychiatry, we show our appreciation by offering a 50% discount on our already low-cost treatment to members of the military and law enforcement whether active or retired. For members of the military and their family covered by Tricare, we offer 6 ketamine infusions for free along with an approved course of TMS treatment as part of our commitment to the military. This offer is subject to terms and conditions that may change without notice.
              </div>
            </ReactTextCollapse>
            <br /> <br />
            <div className={classes.title}>
                6.	Not given with other medications.
                </div>
            <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                <div className={classes.description}>
                    Spravato has all the same potential side effects as IV Ketamine, but without IV access or payment for administration, no medications can be provided to mitigate side effects. This means that about 30% of patients will vomit during the procedure and should be prepared for this possibility. Even if anti-nausea medications were available, they would not be able to effect fast enough. More importantly, the required 2-hour observation is primarily to prevent an unsafe rise in blood pressure. Since the medication administration is all at once, it cannot be slowed or stopped like IV Ketamine, so an unsafe situation requires a call to emergency services and ambulance transport to an emergency room. Additional emergency services would incur additional costs under the patient’s insurance plan.
              </div>
            </ReactTextCollapse>
            <br /> <br />
            <div className={classes.title}>
                7.	It is only for adults.
                </div>
            <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                <div className={classes.description}>
                    Even though Ketamine is safe to use in minors, the FDA approval was only for 18 and up. Many children and adolescents suffer from severe, treatment-resistant disorders just like adults do, and we have successfully treated minors who were in desperate need of help. Our success rate with IV Ketamine in minors is similar to what we see in adults.
              </div>
            </ReactTextCollapse>
        </div>
    );
}