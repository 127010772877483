import React from "react";
import Slider from "react-slick";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-kit-react/views/landingPageSections/blogStyle.js";
const useStyles = makeStyles(styles);

export default function WorkSection() {
  const classes = useStyles();
  const settings = {  
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  return (
    <div className={classes.section}>
      <div>
        <h2 className={classes.title}>Rpsych Blogs</h2>
        <Slider {...settings}>
          <div>
            <div className={classes.background}>
              <div className={classes.logo}>
              </div>
              <a href="http://rpsychtms.com/KetamineTreatmentTheFutureOfDepressionTreatment"><h5 className={classes.titleSlide}>Ketamine Treatment: Is It The Future Of Depression Treatment?</h5></a>
            </div>
          </div>
          <div>
            <div className={classes.background}>
              <div className={classes.logo}>
              </div>
              <a href="http://rpsychtms.com/LookingAfterYourMentalHealthDuringThePandemic"><h5 className={classes.titleSlide}>Riding The Storm: Looking After Your Mental Health During The Pandemic</h5></a>
            </div>
          </div>
          <div>
            <div className={classes.background}>
              <div className={classes.logo}>
              </div>
              <a href="http://rpsychtms.com/AllYouNeedToKnowAboutKetamine"><h5 className={classes.titleSlide}>All You Need To Know About Ketamine Infusion Therapy</h5></a>
            </div>
          </div>
          <div>
            <div className={classes.background}>
              <div className={classes.logo}>
              </div>
              <a href="http://rpsychtms.com/BattlingTheStigmaAroundMentalHealthTreatment"><h5 className={classes.titleSlide}>Battling the Stigma around Mental Health Treatment</h5></a>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
}
