import {
  primaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
} from "assets/jss/material-kit-react.js";

const infoStyle = {
  infoArea: {
    maxWidth: "360px",
    margin: "0 auto",
    padding: "0px"
  },
  iconWrapper: {
    float: "left",
    marginTop: "24px",
    marginRight: "10px"
  },
  primary: {
    color: primaryColor
  },
  warning: {
    color: warningColor
  },
  danger: {
    color: dangerColor
  },
  success: {
    color: successColor
  },
  info: {
    color: infoColor
  },
  rose: {
    color: roseColor
  },
  gray: {
    color: grayColor
  },
  icon: {
    width: "250px",
    height: "250px"
  },
  descriptionWrapper: {
    color: "#505C6A",
    overflow: "hidden",
    backgroundColor: "rgba(255, 255, 255, 0.3)",
    borderRadius: "25px !important",
    backdropFilter: "blur(10px)",
  },
  title: {
    color: "black",
    textDecoration: "none",
    fontWeight: "700",
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
    textAlign: "center !important"
  },
  description: {
    color: "#505C6A",
    overflow: "hidden",
    marginTop: "0px",
    fontSize: "14px"
  },
  iconWrapperVertical: {
    float: "none"
  },
  iconVertical: {
    width: "100px",
    height: "100px",
  }
};

export default infoStyle;


// block: {
//   width: "50%",
//   margin: "30px auto 30px auto",
//   boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.40)",
//   position: "relative",
//   overflow: "hidden",
// },
// blur: {
//   background: "url(" + image + ") noRepeat, center, center",
//   backgroundSize: "cover",
//   overflow: "hidden",
//   zIndex: "50",
//   position: "absolute",
//   top: "0",
//   bottom: "0",
//   left: "0",
//   right: "0",
//   transform: "scale(2)",
//   filter: "blur(10px)",
// },
// content: {
//   width: "100%",
//   height: "100%",
//   zIndex: "100",
//   position: "relative"
// background: "url(" + image + ") noRepeat center center",
// backgroundSize: "cover",
// width: "100%",
// height: "100%",
// overflow: "auto",