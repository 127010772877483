import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(styles);

export default function Testimonials() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>Patient Testimonials</h2>
          <h5 className={classes.description}>
          Our patients show us appreciation and gratitude regulary and to celebrate that, the following are real testimonials from our patients here at Redemption Psychiatry who were willing to share their powerful stories. We encourage you to read through others experiences and even share your story with us as well.
          </h5>
        </GridItem>
      </GridContainer>
    </div>
  );
}
