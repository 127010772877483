/*eslint-disable*/
import React from "react";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";
import Chip from '@material-ui/core/Chip';
// @material-ui/icons
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import GroupIcon from '@material-ui/icons/Group';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import FaceIcon from '@material-ui/icons/Face';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';
import LocalHospitalSharpIcon from '@material-ui/icons/LocalHospitalSharp';
import WebIcon from '@material-ui/icons/Web';
import WorkIcon from '@material-ui/icons/Work';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import AssignmentInd from '@material-ui/icons/AssignmentInd'
// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="About us"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          buttonIcon={GroupIcon}
          dropdownList={[
            <a
              className={classes.dropdownLink}
              href="https://rpsych.com/provider-biographies"
              target='_blank'
            ><FaceIcon className={classes.icons} />Meet The Team</a>,
            <Link to="/contact-page" className={classes.dropdownLink}>
              <PhoneInTalkIcon className={classes.icons} />Contact Us
            </Link>,
            <Link to="/pricing-page" className={classes.dropdownLink}>
              <AttachMoneyIcon className={classes.icons} />Pricing
            </Link>,
            <Link to="/testimonials-page" className={classes.dropdownLink}>
              <RecordVoiceOverIcon className={classes.icons} />Patient Testimonials
            </Link>,
            <Link to="/providers-page" className={classes.dropdownLink}>
              <WorkIcon className={classes.icons} />For Outside Providers
            </Link>,
            <a
              className={classes.dropdownLink}
              href="https://workforcenow.adp.com/mascsr/default/mdf/recruitment/recruitment.html?cid=d7a56439-403d-4804-b913-8cf397a9df48&ccId=19000101_000001&type=JS&lang=en_US"
              target='_blank'
              rel="noopener"
            ><AssignmentInd className={classes.icons} />Employment Opportunities</a>,
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href="/diagnosis-page"
          color="transparent"
          className={classes.navLink}
        >  <LocalHospitalSharpIcon />Treatments
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href="/whattoexpect-page"
          color="transparent"
          className={classes.navLink}
        >  <EventAvailableIcon />What To Expect
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href="/faq-page"
          color="transparent"
          className={classes.navLink}
        >  <QuestionAnswerIcon />Frequently Asked Questions
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href="https://rpsych.com"
          color="transparent"
          className={classes.navLink}
        >  <WebIcon />Main Site
        </Button>
      </ListItem>
      <ListItem className={classes.listItemPhone}>
        <Chip
          color="primary"
          label="Call us!"
          component="a"
          href="tel:+1-480-576-8666"
          clickable />

      </ListItem>
    </List>
  );
}
