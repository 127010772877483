import React from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import Container from '@material-ui/core/Container';
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import profile from "assets/img/RLogohalfsize.png";
import styles from "assets/jss/material-kit-react/views/profilePage.js";

const useStyles = makeStyles(styles);

export default function PricingPage(props) {
    const classes = useStyles();
    const { ...rest } = props;
    const imageClasses = classNames(
        classes.imgRoundedCircle,
        classes.imgFluid
    );
    return (
        <div>
            <HelmetProvider>
                <Helmet>
                    <title>Redemption Psychiatry Infusions | Infusions and Ketamine Treatments in Arizona</title>
                    <meta name="description" content="THIS NOTICE DESCRIBES HOW MEDICAL INFORMATION ABOUT YOU MAY BE USED, DISCLOSED AND SAFEGUARDED, AND HOW YOU CAN GET ACCESS TO THIS INFORMATION. PLEASE REVIEW IT CAREFULLY." />
                    <meta property="og:locale" content="en_US" />
                    <meta property="og:type" content="website" />
                    <meta property="og:title" content="Redemption Psychiatry Infusions | Infusions and Ketamine Treatments in Arizona" />
                    <meta property="og:description" content="THIS NOTICE DESCRIBES HOW MEDICAL INFORMATION ABOUT YOU MAY BE USED, DISCLOSED AND SAFEGUARDED, AND HOW YOU CAN GET ACCESS TO THIS INFORMATION. PLEASE REVIEW IT CAREFULLY." />
                    <meta property="og:url" content="https://www.rpsychinfusions.com/privacypolicy" />
                    <meta property="og:site_name" content="Redemption Psychiatry Ketamine Infusions" />
                    <meta property="og:image" content="https://my.datasphere.com/files/mydatasphere/styles/full_640/public/business/images/0419/1555625884_12657239_RedemptionPsychiatry_500x500.png?itok=Qrowvv_y" />
                    <meta property="og:image:secure_url" content="https://my.datasphere.com/files/mydatasphere/styles/full_640/public/business/images/0419/1555625884_12657239_RedemptionPsychiatry_500x500.png?itok=Qrowvv_y" />
                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:title" content="Redemption Psychiatry Infusions | Infusions and Ketamine Treatments in Arizona" />
                    <meta name="twitter:description" content="THIS NOTICE DESCRIBES HOW MEDICAL INFORMATION ABOUT YOU MAY BE USED, DISCLOSED AND SAFEGUARDED, AND HOW YOU CAN GET ACCESS TO THIS INFORMATION. PLEASE REVIEW IT CAREFULLY." />
                    <meta name="twitter:image" content="https://my.datasphere.com/files/mydatasphere/styles/full_640/public/business/images/0419/1555625884_12657239_RedemptionPsychiatry_500x500.png?itok=Qrowvv_y" />
                    <link rel='dns-prefetch' href='//www.google.com' />
                    <link rel="canonical" href="https://www.rpsychinfusions.com/privacypolicy" />
                </Helmet>
            </HelmetProvider>
            <Header
                color="transparent"
                rightLinks={<HeaderLinks />}
                fixed
                changeColorOnScroll={{
                    height: 200,
                    color: "white"
                }}
                {...rest}
            />
            <Parallax small filter image={require("assets/img/landing-bg-clouds.jpeg")} />
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div>
                    <div className={classes.container}>
                        <GridContainer justify="center">
                            <div className={classes.profilePrice}>
                                <div>
                                    <img src={profile} alt="Redemption Psychiatry Ketamine Therapy Center" className={imageClasses} />
                                </div>
                                <Container maxWidth="lg" component="main" className={classes.heroContent}>
                                    <div className={classes.namePrice}>
                                        <h2 className={classes.title}>NOTICE OF PRIVACY PRACTICES</h2>
                                        <br></br>
                                        <h5 className={classes.title}>THIS NOTICE DESCRIBES HOW MEDICAL INFORMATION ABOUT YOU MAY BE USED, DISCLOSED AND SAFEGUARDED, AND HOW YOU CAN GET ACCESS TO THIS INFORMATION. PLEASE REVIEW IT CAREFULLY.</h5>
                                        <br></br><br></br>
                                        <Typography variant="body1" align="center" color="textPrimary" component="p">
                                        <strong>I. WHO IS SUBJECT TO THIS NOTICE</strong><br></br>
                                      All affiliates of Redemption Psychiatry LLC.
                                      <br></br><br></br>
                                      <strong>II. OUR RESPONSIBILITY</strong><br></br>
                                      The confidentiality of your personal health information is very important to us. Your health information includes records that we create and obtain when we provide you care, such as a record of your symptoms, examination and test results, diagnoses, treatments and referrals for further care. It also includes bills, insurance claims, or other payment information that we maintain related to your care.
                                      This Notice describes how we handle your health information and your rights regarding this information. Generally speaking, we are required to:
                                      <br></br>
                                      Maintain the privacy of your health information as required by law;
                                      Provide you with this Notice of our duties and privacy practices regarding the health information about you that we collect and maintain;
                                      Follow the terms of our Notice currently in effect.
                                      <br></br><br></br>
                                      <strong>III. CONTACT INFORMATION</strong><br></br>
                                      After reviewing this Notice, if you need further information or want to contact us for any reason regarding the handling of your health information, please direct any communications to the following:
                                      <br></br>
                                      Redemption Psychiatry LLC
                                      2730 S. Val Vista Dr. Ste. 152
                                      Gilbert, AZ 85295
                                      Phone: 480-471-8560 Fax: 888-979-8197
                                      <br></br><br></br>
                                      <strong>IV. USES AND DISCLOSURES OF INFORMATION</strong><br></br>
                                      Under federal and Arizona state law, we are permitted to use and disclose personal health information without authorization for treatment, payment, and health care operations. However, the American Psychiatric Association's Principles of Medical Ethics or state law may require us to obtain your express consent before we make certain disclosures of your personal health information. Healthcare providers of Redemption Psychiatry LLC also share health information with each other, as necessary to carry out treatment, payment, or health care operations relating to the provision of patient care.
                                      <br></br><br></br>
                                      <strong>V. OTHER USES AND DISCLOSURES</strong><br></br>
                                      In addition to uses and disclosures related to treatment, payment, and health care operations, we may also use and disclose your personal information without authorization for the following additional purposes:
                                      <br></br>
                                      Abuse, Neglect, or Domestic Violence<br></br>
                                      As required or permitted by law, we may disclose health information about you to a state or federal agency to report suspected abuse, neglect, or domestic violence. If such a report is optional, we will use our professional judgment in deciding whether or not to make such a report. If feasible, we will inform you promptly that we have made such a disclosure.
                                      <br></br>
                                      Appointment Reminders and Other Health Services<br></br>
                                      We may use or disclose your health information to remind you about appointments or to inform you about treatment alternatives or other health-related benefits and services that may be of interest to you, such as case management or care coordination.
                                      <br></br>
                                      Business Associates<br></br>
                                      We may share health information about you with business associates who are performing services on our behalf. For example, we may contract with a company to service and maintain our computer systems, or to do our billing. Our business associates are obligated to safeguard your health information. We will share with our business associates only the minimum amount of personal health information necessary for them to assist us.
                                      <br></br>
                                      Communicable Diseases<br></br>
                                      To the extent authorized by law, we may disclose information to a person who may have been exposed to a communicable disease or who is otherwise at risk of spreading a disease or condition.
                                      <br></br>
                                      Communications with Family and Friends<br></br>
                                      We may disclose information about you to persons who are involved in your care or payment for your care, such as family members, relatives, or close personal friends. Any such disclosure will be limited to information directly related to the person's involvement in your care. If you are available, we will provide you an opportunity to object before disclosing any such information. If you are unavailable because, for example, you are incapacitated or because of some other emergency circumstance, we will use our professional judgment to determine what is in your best interest regarding any such disclosure.
                                      <br></br>
                                      Coroners, Medical Examiners, and Funeral Directors<br></br>
                                      We may disclose health information about you to a coroner or medical examiner, for example, to assist in the identification of a decedent or determining cause of death. We may also disclose health information to funeral directors to enable them to carry out their duties.
                                      <br></br>
                                      Disaster Relief<br></br>
                                      We may disclose health information about you to government entities or private organizations (such as the Red Cross) to assist in disaster relief efforts.
                                      If you are available, we will provide you an opportunity to object before disclosing any such information. If you are unavailable because, for example, you are incapacitated, we will use our professional judgment to determine what is in your best interest and whether a disclosure may be necessary to ensure an adequate response to the emergency circumstances.
                                      <br></br>
                                      Food and Drug Administration (FDA)<br></br>
                                      We may disclose health information about you to the FDA, or to an entity regulated by the FDA, in order, for example, to report an adverse event or a defect related to a drug or medical device.
                                      <br></br>
                                      Health Oversight<br></br>
                                      We may disclose health information about you for oversight activities authorized by law or to an authorized health oversight agency to facilitate auditing, inspection, or investigation related to our provision of health care, or to the health care system.
                                      <br></br>
                                      Judicial or Administrative Proceedings<br></br>
                                      We may disclose health information about you in the course of a judicial or administrative proceeding, in accordance with our legal obligations.
                                      <br></br>
                                      Law Enforcement<br></br>
                                      We may disclose health information about you to a law enforcement official for certain law enforcement purposes. For example, we may report certain types of injuries as required by law, assist law enforcement to locate someone such as a fugitive or material witness, or make a report concerning a crime or suspected criminal conduct.
                                      <br></br>
                                      Minors<br></br>
                                      If you are an unemancipated minor under Arizona State law, there may be circumstances in which we disclose health information about you to a parent, guardian, or other person acting in loco parentis, in accordance with our legal and ethical responsibilities.
                                      <br></br>
                                      Notification<br></br>
                                      We may notify a family member, your personal representative, or other person responsible for your care, of your location, general condition, or death.
                                      If you are available, we will provide you an opportunity to object before disclosing any such information. If you are unavailable because, for example, you are incapacitated or because of some other emergency circumstance, we will use our professional judgment to determine what is in your best interest regarding any such disclosure.
                                      <br></br>
                                      Organ and Tissue Donation<br></br>
                                      We may disclose health information about you to organ procurement organizations or similar entities to facilitate organ, eye, or tissue donation and transplantation.
                                      <br></br>
                                      Parents<br></br>
                                      If you are a parent of an unemancipated minor, and are acting as the minor's personal representative, we may disclose health information about your child to you under certain circumstances. For example, if we are legally required to obtain your consent as your child's personal representative in order for your child to receive care from us, we may disclose health information about your child to you.
                                      In some circumstances, we may not disclose health information about an unemancipated minor to you. For example, if your child is legally authorized to consent to treatment (without separate consent from you), consents to such treatment, and does not request that you be treated as his or her personal representative, we may not disclose health information about your child to you without your child's written authorization.
                                      <br></br>
                                      Personal Representative<br></br>
                                      If you are an adult or emancipated minor, we may disclose health information about you to a personal representative authorized to act on your behalf in making decisions about your health care.
                                      <br></br>
                                      Public Health Activities<br></br>
                                      As required or permitted by law, we may disclose health information about you to a public health authority, for example, to report disease, injury, or vital events such as death.
                                      <br></br>
                                      Public Safety<br></br>
                                      Consistent with our legal and ethical obligations, we may disclose health information about you based on a good faith determination that such disclosure is necessary to prevent a serious and imminent threat to the public or to identify or apprehend an individual sought by law enforcement.
                                      <br></br>
                                      Required By Law<br></br>
                                      We may disclose health information about you as required by federal, state, or other applicable law.
                                      <br></br>
                                      Research<br></br>
                                      We may disclose health information about you for research purposes in accordance with our legal obligations. For example, we may disclose health information without a written authorization if an Institutional Review Board (IRB) or authorized privacy board has reviewed the research project and determined that the information is necessary for the research and will be adequately safeguarded.
                                      <br></br>
                                      Specialized Government Functions<br></br>
                                      We may disclose health information about you for certain specialized government functions, as authorized by law. Among these functions are the following: military command; determination of veterans' benefits; national security and intelligence activities; protection of the President and other officials; and the health, safety, and security of correctional institutions.
                                      <br></br>
                                      Workers' Compensation<br></br>
                                      We may disclose health information about you for purposes related to workers' compensation, as required and authorized by law.
                                      <br></br><br></br>
                                      <strong>VI. PSYCHOTHERAPY NOTES</strong><br></br>
                                      In the course of your care with us, you may receive treatment from a mental health professional (such as a psychiatrist) who keeps separate notes during the course of your therapy sessions about your conversations. These notes, known as “psychotherapy notes”, are kept apart from the rest of your medical record, and do not include basic information such as your medication treatment record, counseling session start and stop times, the types and frequencies of treatment you receive, or your test results. They also do not include any summary of your diagnosis, condition, treatment plan, symptoms, prognosis, or treatment progress.
                                      Psychotherapy notes may be disclosed by a therapist only after you have given written authorization to do so. (Limited exceptions exist, e.g. in order for your therapist to prevent harm to yourself or others, and to report child abuse/neglect). You cannot be required to authorize the release of your psychotherapy notes in order to obtain health-insurance benefits for your treatment, or enroll in a health plan. Psychotherapy notes are also not among the records that you may request to review or copy (see discussion of your rights in section VII below). If you have any questions, feel free to discuss this subject with your therapist.
                                      <br></br><br></br>
                                      <strong>VII. YOUR HEALTH INFORMATION RIGHTS</strong><br></br>
                                      Under the law, you have certain rights regarding the health information that we collect and maintain about you. This includes the right to:
                                      <br></br>
                                      Request that we restrict certain uses and disclosures of your health information; we are not, however, required to agree to a requested restriction.<br></br>
                                      Request that we communicate with you by alternative means, such as making records available for pick-up, or mailing them to you at an alternative address, such as a P.O. Box. We will accommodate reasonable requests for such confidential communications.
                                      Request to review, or to receive a copy of, the health information about you that is maintained in our files and the files of our business associates (if applicable). If we are unable to satisfy your request, we will tell you in writing the reason for the denial and your right, if any, to request a review of the decision.<br></br>
                                      Request that we amend the health information about you that is maintained in our files and the files of our business associates (if applicable). Your request must explain why you believe our records about you are incorrect, or otherwise require amendment. If we are unable to satisfy your request, we will tell you in writing the reason for the denial and tell you how you may contest the decision, including your right to submit a statement (of reasonable length) disagreeing with the decision. This statement will be added to your records.<br></br>
                                      Request a list of our disclosures of your health information. This list, known as an “accounting” of disclosures, will not include certain disclosures, such as those made for treatment, payment, or health care operations. We will provide you the accounting free of charge, however if you request more than one accounting in any 12 month period, we may impose a reasonable, costbased fee for any subsequent request. Your request should indicate the period of time in which you are interested (for example, “from May 1, 2003 to June 1, 2003”). We will be unable to provide you an accounting for any disclosures made before June 1st, 2007 or for a period of longer than six years.
                                      <br></br>
                                      Request a paper copy of this Notice.<br></br>
                                      In order to exercise any of your rights described above, you must submit your request in writing to our contact person (see section III above for information). If you have questions about your rights, please speak with our office, available in person or by phone, during normal office hours.
                                      <br></br><br></br>
                                      <strong>VIII. TO REQUEST INFORMATION OR FILE A COMPLAINT</strong><br></br>
                                      If you believe your privacy rights have been violated, you may file a written complaint by mailing it or delivering it to our office (see section III above). You may complain to the Secretary of Health and Human Services (HHS) by Contacting:
                                      Office for Civil Rights, U.S. Department of Health and Human Services
                                      200 Independence Avenue, S.W., Room 509F, HHH Building
                                      Washington, D.C. 20201
                                      Phone: 1-800-368-1019
                                      Email: OCRprivacy@hhs.gov.
                                      <br></br>
                                      Office of Human Rights, Division of Behavioral Health Services
                                      150 N. 18th Ave #210
                                      Phoenix, AZ 85007
                                      Phone: 602-364-4558
                                      <br></br>
                                      We cannot, and will not, make you waive your right to file a complaint with HHS as a condition of receiving care from us, or penalize you for filing a complaint with HHS.
                                      <br></br><br></br>
                                      <strong>IX. REVISIONS TO THIS NOTICE</strong><br></br>
                                      We reserve the right to amend the terms of this Notice. If this Notice is revised, the amended terms shall apply to all health information that we maintain, including information about you collected or obtained before the effective date of the revised Notice. If the revisions reflect a material change to the use and disclosure of your information, your rights regarding such information, our legal duties, or other privacy practices described in the Notice, we will promptly distribute the revised Notice, post it in the waiting area(s) of our office and make copies available to our patients and others, and post it on our website.
                                      <br></br><br></br>
                                      <strong>X. EFFECTIVE DATE:</strong><br></br>
                                      July 15, 2013
                                        </Typography>
                                    </div>
                                </Container>
                            </div>
                        </GridContainer>
                        <br></br>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
