import { title1 } from "assets/jss/material-kit-react.js";

const productStyle = {
  section: {
    padding: "0px 0",
    textAlign: "center"
  },
  title: {
    ...title1,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    textAlign: "center"
  },
  title2: {
    ...title1,
    marginBottom: "1rem",
    marginTop: "1px",
    minHeight: "32px",
    textDecoration: "none",
    textAlign: "center"
  },
  title3: {
    ...title1,
    marginBottom: "1px",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    textAlign: "center"
  },
  description: {
    color: "black",
    fontWeight: "400",
    fontFamily: `"Roboto Slab", "Times New Roman", serif`,
    textAlign: "center"
  },
  showMore: {
    display: "inline",
    position: "relative",
    top: "-7px",
  },
  showLess: {
    display: "inline",
    position: "relative",
    top: "-7px",
  },
  iconHover: {
    "&:hover": {
      color: "ed1212",
      cursor: "pointer",
    }
  }
};

export default productStyle;
