import React, { Component } from 'react';
import { Map, GoogleApiWrapper, InfoWindow, Marker } from 'google-maps-react';

const mapStyles = {
  width: '100%',
  height: '100%',
  borderBottomRightRadius: "6px",
  borderBottomLeftRadius: "6px",
};


export class GoogleMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      offices: [{ lat: 33.484443, lng: -112.071474 },
      {
        latitude: 33.295507,
        longitude: -111.876810,
        name: <React.Fragment>Redemption Psychiatry Ketamine Clinic <br /> Chandler</React.Fragment>,
        address: <React.Fragment>600 S Dobson Road, Suite 36-E <br /> Chandler, AZ 85224</React.Fragment>,
        directions: "http://maps.apple.com/?q=Redemption+Psychiatry+Chandler&sll=33.643983,-112.230209&z=10&t=s"
      },
      {
        latitude: 33.300253,
        longitude: -111.757823,
        name: <React.Fragment>Redemption Psychiatry Ketamine Clinic <br /> Gilbert</React.Fragment>,
        address: <React.Fragment>2730 S Val Vista Drive, Suite 146 <br /> Gilbert, AZ 85295</React.Fragment>,
        directions: "http://maps.apple.com/?q=Redemption+Psychiatry+Gilbert&sll=33.300253,-111.757823&z=10&t=s"
      }],
      showingInfoWindow: false,  //Hides or the shows the infoWindow
      activeMarker: {},          //Shows the active marker upon click
      selectedPlace: {}          //Shows the infoWindow to the selected place upon a marker
    }
  }
  // Chandler:  33.295507, -111.876810 -- ChIJl74JGrkAK4cRNfLRySQugUQ -- https://www.google.com/maps/search/?api=1&query=33.295507,-111.876810&query_place_id=ChIJl74JGrkAK4cRNfLRySQugUQ
  // Gilbert:   33.300253, -111.757823 -- ChIJWcjaD3esK4cRVEEafhTUVYA -- https://www.google.com/maps/search/?api=1&query=33.300253,-111.757823&query_place_id=ChIJWcjaD3esK4cRVEEafhTUVYA

  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true
    });

  onClose = props => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      });
    }
  };

  displayMarkers = () => {
    return this.state.offices.map((office, index) => {
      return <Marker key={index} id={index} position={{
        lat: office.latitude,
        lng: office.longitude,
      }}
        name={office.name}
        address={office.address}
        directions={office.directions}
        onClick={this.onMarkerClick} />
    })
  }

  render() {
    return (
      <Map
        google={this.props.google}
        zoom={11}
        style={mapStyles}
        initialCenter={{ lat: 33.299, lng: -111.817 }}
      >
        {this.displayMarkers()}
        <InfoWindow
          marker={this.state.activeMarker}
          visible={this.state.showingInfoWindow}
          onClose={this.onClose}
        >
          <div>
            <h4 style={{ textAlign: "left" }}>{this.state.selectedPlace.name}</h4>
            <hr></hr>
            <p>{this.state.selectedPlace.address}</p>
            <button><a href={this.state.selectedPlace.directions}>Get Directions!</a></button>
          </div>
        </InfoWindow>
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: process.env.REACT_APP_MAP_KEY
})(GoogleMap);


