import React from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import Container from '@material-ui/core/Container';
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import profile from "assets/img/RLogohalfsize.png";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
//sections
import GeneralFAQPaneltest from "./FAQ's/GeneralFAQtest.js";

const useStyles = makeStyles(styles);

export default function PricingPage(props) {
    const classes = useStyles();
    const { qanda, ...rest } = props;
    const imageClasses = classNames(
        classes.imgRounded,
        classes.imgFluid
    );
    return (
        <div>
            <HelmetProvider>
                <Helmet>
                    <title>Redemption Psychiatry Infusions | Frequently Asked Questions and Answers About Ketamine Therapy</title>
                    <meta name="description" content="Have you ever wondered what is Ketamine Infusion Therapy? What does it treat? How does it work? Is it safe? Everyone has unique health needs and lives, get answers to your specific questions or concerns and discover if our treatments are right for you! Learn more about this revolutionary treatment option for Depression, Anxiety, PTSD, Chronic Pain and Other Conditions." />
                    <meta property="og:locale" content="en_US" />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content="Redemption Psychiatry Infusions | Frequently Asked Questions and Answers About Ketamine Therapy" />
                    <meta property="og:description" content="Have you ever wondered what is Ketamine Infusion Therapy? What does it treat? How does it work? Is it safe? Everyone has unique health needs and lives, get answers to your specific questions or concerns and discover if our treatments are right for you! Learn more about this revolutionary treatment option for Depression, Anxiety, PTSD, Chronic Pain and Other Conditions." />
                    <meta property="og:url" content="https://www.rpsychinfusions.com/faq-page" />
                    <meta property="og:site_name" content="Redemption Psychiatry Ketamine Infusions" />
                    <meta property="og:image" content="https://my.datasphere.com/files/mydatasphere/styles/full_640/public/business/images/0419/1555625884_12657239_RedemptionPsychiatry_500x500.png?itok=Qrowvv_y" />
                    <meta property="og:image:secure_url" content="https://my.datasphere.com/files/mydatasphere/styles/full_640/public/business/images/0419/1555625884_12657239_RedemptionPsychiatry_500x500.png?itok=Qrowvv_y" />
                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:title" content="Redemption Psychiatry Infusions | Frequently Asked Questions and Answers About Ketamine Therapy" />
                    <meta name="twitter:description" content="Have you ever wondered what is Ketamine Infusion Therapy? What does it treat? How does it work? Is it safe? Everyone has unique health needs and lives, get answers to your specific questions or concerns and discover if our treatments are right for you! Learn more about this revolutionary treatment option for Depression, Anxiety, PTSD, Chronic Pain and Other Conditions." />
                    <meta name="twitter:image" content="https://my.datasphere.com/files/mydatasphere/styles/full_640/public/business/images/0419/1555625884_12657239_RedemptionPsychiatry_500x500.png?itok=Qrowvv_y" />
                    <link rel='dns-prefetch' href='//www.google.com' />
                    <link rel="canonical" href="https://www.rpsychinfusions.com/faq-page" />
                </Helmet>
            </HelmetProvider>
            <Header
                color="transparent"
                rightLinks={<HeaderLinks />}
                fixed
                changeColorOnScroll={{
                    height: 200,
                    color: "white"
                }}
                {...rest}
            />
            <Parallax small filter image={require("assets/img/pismopier.jpg")} />
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.container}>
                    <GridContainer justify="center">
                        <div className={classes.profilePrice}>
                            <div>
                                <img src={profile} alt="Redemption Psychiatry Building" className={imageClasses} />
                            </div>
                            <Container maxWidth="lg" component="main" className={classes.heroContent}>
                                <div className={classes.namePrice}>

                                    <h2 className={classes.title}>Frequently Asked Questions</h2>
                                    <h5 className={classes.description}>
                                        Click on any of the questions below or search for a keyword to learn more!
                                        </h5>
                                    <br></br><br></br>
                                </div>
                            </Container>
                        </div>
                    </GridContainer>
                    <GeneralFAQPaneltest />
                    <GridContainer justify="center">
                        <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                            DISCLAIMER
                            </Typography>
                        <Typography variant="body1" align="center" color="textSecondary" component="div">
                            It is important to fully educate yourself before pursuing any medical treatment. Deciding to undergo Ketamine infusion therapy is no different, and we hope this information will be helpful in better understanding the treatment. Please note that the information contained on this webpage is not intended as an advertisement for treatment. You must be prescribed Ketamine treatment by a mental health specialist. Like all medical treatments, we cannot guarantee results or promise there will not be any adverse effects. Although many patients experience total relief from their first infusion or after multiple infusions, it is possible for patients to have no response from treatment. Each individual’s response is unique and dependent on several factors, so you should know that your response may not be typical and should not be compared with the experiences of others or research outcomes.
                            </Typography><br></br>
                    </GridContainer>
                </div>
            </div>
            <Footer />
        </div>
    );
}

