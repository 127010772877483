import React from "react";
// material-ui components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
// import GridContainer from "components/Grid/GridContainer.js";
// import GridItem from "components/Grid/GridItem.js";
import GoogleMapWest from "./GoogleMapWest"
import imagesStyles from "assets/jss/material-kit-react/imagesStyles.js";

import { cardTitle } from "assets/jss/material-kit-react.js";

const styles = theme => ({
  ...imagesStyles,
  cardTitle,
  textMuted: {
    color: "#6c757d"
  },
  lineLeft: {
      borderLeft: "1px solid black",
      height: "100px",
      [theme.breakpoints.down("sm")]: {
        borderTop: "0px solid black",
        borderLeft: "0px",
        height: "100px",
      },
  },
  lineRight: {
    borderRight: "1px solid black",
    height: "100px",
    [theme.breakpoints.down("sm")]: {
      borderBottom: "0px solid black",
      borderRight: "0px",
      height: "100px"
    },
}
});

const useStyles = makeStyles(styles);

export default function Cards() {
  const classes = useStyles();
  return(
    <div>
      <Card>
        <CardBody>
        <h4 className={classes.cardTitle}>Phoenix  Office</h4>
        <hr />
       
          {/* <h5 style={{marginLeft: "15px"}}><strong>Phoenix Office</strong></h5> */}
          <p style={{textAlign: "center", marginBottom: "25px", marginTop: "30px"}}>12424 North 32nd Street<br />Phoenix, AZ 85032</p>
               </CardBody>
        <div className={classes.imgCardBottom}>
          <GoogleMapWest />
        </div>
      </Card>
    </div>
  );
};