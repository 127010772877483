import React, { Component } from "react";
import WhatToExpectPage from "./WhatToExpectPage.js"
import WhatToExpectPageMobile from "./WhatToExpectPageMobile.js"


export default class PageChooser extends Component {

  render() {
    let width = window.innerWidth;
    if (width > 700) {
      return (
        <div className="container">
          <WhatToExpectPage />
        </div>
      );
    } else {
      return (
        <div className="container">
          <WhatToExpectPageMobile />
        </div>
      );
    }
  }
}