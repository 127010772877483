import React from "react";
// material-ui components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import GoogleMapEast from "./GoogleMapEast"
import imagesStyles from "assets/jss/material-kit-react/imagesStyles.js";

import { cardTitle } from "assets/jss/material-kit-react.js";

const styles = theme => ({
  ...imagesStyles,
  cardTitle,
  textMuted: {
    color: "#6c757d"
  },
  lineLeft: {
    borderLeft: "1px solid black",
    height: "100px",
    [theme.breakpoints.down("sm")]: {
      borderTop: "0px solid black",
      borderLeft: "0px",
      height: "100px",
    },
  },
  lineRight: {
    borderRight: "1px solid black",
    height: "100px",
    [theme.breakpoints.down("sm")]: {
      borderBottom: "0px solid black",
      borderRight: "0px",
      height: "100px",
    },
  },
});

const useStyles = makeStyles(styles);

export default function Cards() {
  const classes = useStyles();
  return (
    <div>
      <Card>
        <CardBody>
          <h4 className={classes.cardTitle}>East Valley Offices</h4>
          <hr />
          <GridContainer justify="center">
            <GridItem className={classes.lineRight} xs={12} sm={12} md={6}>
              <h5 style={{ marginLeft: "15px" }}><strong>Gilbert Office </strong></h5>
              <p style={{ marginLeft: "15px" }}>2730 S Val Vista Drive, Suite 146<br />Gilbert, AZ 85295</p>
            </GridItem>
            <GridItem className={classes.lineLeft} xs={12} sm={12} md={6}>
              <h5 style={{ marginLeft: "15px" }}><strong>Chandler Office</strong></h5>
              <p style={{ marginLeft: "15px" }}>600 S Dobson Road, Suite 36-E<br></br>Chandler, AZ 85224</p>
            </GridItem>
            <GridItem className={classes.lineRight} xs={12} sm={12} md={6}>
              <h5 style={{ marginLeft: "15px" }}><strong>Mesa Office</strong></h5>
              <p style={{ marginLeft: "15px" }}>3489 E Baseline Road<br></br>Gilbert, AZ 85234</p>
            </GridItem>
            <GridItem className={classes.lineLeft} xs={12} sm={12} md={6}>
              <h5 style={{ marginLeft: "15px" }}></h5>
              <p style={{ marginLeft: "15px" }}></p>
            </GridItem>
          </GridContainer>
        </CardBody>
        <div className={classes.imgCardBottom}>
          <GoogleMapEast />
        </div>
      </Card>
    </div>
  );
};