import { container, title } from "assets/jss/material-kit-react.js";

import image from "assets/img/woman-with-umbrella-on-beach-247304.jpg";
import image2 from "assets/img/photo-of-woman-raising-both-hands-1556691.jpg";
import image3 from "assets/img/landing-bg-clouds.jpeg";

const landingPageStyle = {
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    ...container
  },
  background: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    borderBottomLeftRadius: "6px",
    borderBottomRightRadius: "6px",
    height: "100%",
    width: "100%",
    backgroundImage: "url(" + image + ")",
  },
  background2: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    borderRadius: "6px",
    height: "100%",
    width: "100%",
    backgroundImage: "url(" + image2 + ")"
  },
  background3: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    borderTopLeftRadius: "6px",
    borderTopRightRadius: "6px",
    height: "100%",
    width: "100%",
    backgroundImage: "url(" + image3 + ")"
  },
  title: {
    ...title,
    marginBottom: "0",
    marginTop: "0px",
    minHeight: "32px",
    textDecoration: "none",
    textAlign: "center"
  },
  titleFrost: {
    ...title,
    marginBottom: "0",
    marginTop: "0px",
    minHeight: "32px",
    textDecoration: "none",
    textAlign: "center",
    backgroundColor: "rgba(255, 255, 255, 0.3)",
    borderRadius: "25px !important",
    backdropFilter: "blur(10px)",
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0"
  },
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  description: {
    ...title,
    textAlign: "center",
  },
  descriptionFrost: {
    ...title,
    textAlign: "center",
    backgroundColor: "rgba(255, 255, 255, 0.3)",
    borderRadius: "15px !important",
    backdropFilter: "blur(10px)",
  }
};

export default landingPageStyle;

// textShadow: "0px 4px 3px rgba(0,0,0,0.4), 0px 8px 13px rgba(0,0,0,0.1), 0px 18px 23px rgba(0,0,0,0.1);",
