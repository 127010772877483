import React from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/StarBorder';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
// import image from "assets/img/extras/pngfind.com-rain-cloud-png-7421.png";

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: "theme.palette.common.white",
    },
    ul: {
      margin: 0,
      padding: 0,
    },
    li: {
      listStyle: 'inside',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor: theme.palette.grey[200],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  cardBg: {
    // backgroundImage: "url(" + image + ")",
    // backgroundSize: "cover",
  }
}));

const tiers = [
  {
    title: 'First Treatment',
    price: '0',
    description: [
      'Price of consult varies based on patient insurance (covered by most private insurances)',
      'Meet with our expert physicians',
      'Determine if treatment is right for you',
      'Pay for only the treatments you need!'
    ],
    buttonText: 'Get Started',
    buttonVariant: 'outlined',
  },
  {
    title: 'Follow-up Treatment',
    subheader: 'Most popular',
    price: '500',
    description: [
      '40-60 minute low-dose infusion',
      'Patients typically spend 1.5 hours in the office',
      'Spa-like environment',
      'The most appropriate course of treatments will be different for each patient',
      'Healthcare Savings Accounts (HSA) can be used',
      'Payment plans are available',
    ],
    desc2: 'OR LESS!',
    buttonText: 'Call now to schedule',
    buttonVariant: 'contained',
  },
  {
    title: 'Community Heroes',
    price: '0',
    description: [
      'We show our appreciation to our community heros (first responder, medical professional, military, and others)',
      'Call us for details regarding our discount program',
      '**Tricare members: we offer 6 ketamine infusions for free with an approved course of TMS treatment as part of our commitment to the military'
    ],
    buttonText: 'Contact us',
    buttonVariant: 'outlined',
  },
];
export default function Pricing() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Container maxWidth="lg" component="main">
        <Grid container spacing={5} alignItems="flex-end">
          {tiers.map(tier => (
            // Enterprise card is full width at sm breakpoint
            <Grid item key={tier.title} xs={12} sm={tier.title === 'Enterprise' ? 12 : 6} md={4}>
              <br></br>
              <Card>
                <CardHeader
                  title={tier.title}
                  subheader={tier.subheader}
                  titleTypographyProps={{ align: 'center' }}
                  subheaderTypographyProps={{ align: 'center' }}
                  action={tier.title === 'Standard' ? <StarIcon /> : null}
                  className={classes.cardHeader}
                />
                <CardContent className={classes.cardBg}>
                  {tier.price === '0' ?
                    null:
                    <div className={classes.cardPricing} style={{ margin: '0px' }}>
                      <Typography component="h2" variant="h3" color="textPrimary">
                        ${tier.price}
                      </Typography>
                      <Typography variant="h6" color="textSecondary">
                        /ea
                      </Typography>
                    </div>}
                  <div className={classes.cardPricing}>
                    {tier.desc2 ?
                      <div>({tier.desc2})</div>
                      : null
                    }</div>
                  <ul>
                    {tier.description.map(line => (
                      <Typography component="li" variant="subtitle1" align="center" key={line}>
                        {line}<br></br>
                      </Typography>
                    ))}
                  </ul>
                </CardContent>
                <CardActions>
                  <Button fullWidth variant={tier.buttonVariant} color="primary">
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </React.Fragment>
  );
}