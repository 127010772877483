import React from "react";
// material-ui components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import { cardTitle } from "assets/jss/material-kit-react.js";

const styles = {
    cardTitle,
    textCenter: {
        textAlign: "center"
    },
    textRight: {
        textAlign: "right"
    },
    justifyCenter: {
        justifyContent: "center",
    }
};

const useStyles = makeStyles(styles);

export default function Cards() {
    const classes = useStyles();
    return (
        <div>
            <GridContainer className={classes.justifyCenter}>
                <GridItem xs={12} sm={4}>
                    <Card >
                        <CardHeader className={classes.textCenter} color="primary">Ineffective treatments</CardHeader>
                        <CardBody>
                            <p>
                                Examine how much you’re spending on current treatments that aren’t working. Multiple medication trials, frequent doctor visits, and ongoing therapy appointments can be very costly, even if they are covered by insurance. Most of our patients have been able to reduce or eliminate ineffective treatments and save hundreds of dollars per month.
                            </p>
                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={4}>
                    <Card>
                        <CardHeader className={classes.textCenter} color="primary">Occupational problems</CardHeader>
                        <CardBody>
                            <p>
                                A condition that prevents you from working, finding a job, getting promoted, or even just being productive at work can cost thousands of dollars monthly.
                            </p>
                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={4}>
                    <Card>
                        <CardHeader className={classes.textCenter} color="primary">Educational problems</CardHeader>
                        <CardBody>
                            <p>
                                Patients may fail classes, drop classes, or withdraw completely due to their symptoms. This can result in lost tuition, repeating courses, lost scholarships, delays in graduating, and even abandoning educational and career goals. The actual cost can be in the tens of thousands, but the intangible costs can be incalculable.
                            </p>
                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={4}>
                    <Card>
                        <CardHeader className={classes.textCenter} color="primary">Relationship disruption</CardHeader>
                        <CardBody>
                            <p>
                                The impact on friends, family, and romantic relationships cannot be measured, but the cost is very real. Effective treatment may help prevent losing a friend, becoming estranged from family, breaking up a relationship, or even divorcing.
                            </p>
                        </CardBody>
                    </Card>
                </GridItem>
                <GridItem xs={12} sm={4}>
                    <Card>
                        <CardHeader className={classes.textCenter} color="primary">Serious adverse events</CardHeader>
                        <CardBody>
                            <p>
                                Hospitalizations, suicide attempts, and even death can result from inadequate response to treatment. The cost can be financially devastating and potentially immeasurable.
                            </p>
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}