import React from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import profile from "assets/img/RLogohalfsize.png";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
//sections
import CardMapEast from './Sections/CardMapEast.js';
import CardMapWest from './Sections/CardMapWest.js';
import ContactForm from './Sections/ContactForm.js';

const useStyles = makeStyles(styles);

export default function ContactPage(props) {
    const classes = useStyles();
    const { ...rest } = props;
    const imageClasses = classNames(
        classes.imgRounded,
        classes.imgFluid
    );
    return (
        <div>
            <HelmetProvider>
                <Helmet>
                    <title>Contact Redemption Psychiatry (480) 576-8666 | Safe and Effective Ketamine Treatments in Arizona</title>
                    <meta name="description" content="Redemption Psychiatry has 5 convenient locations throughout Arizona. Visit us in Gilbert, Scottsdale, Phoenix, Chandler or Glendale today!" />
                    <meta property="og:locale" content="en_US" />
                    <meta property="og:type" content="activity" />
                    <meta property="og:title" content="Contact Redemption Psychiatry (480) 576-8666 | Safe and Effective Ketamine Treatments in Arizona" />
                    <meta property="og:description" content="An Exceptional Treatment Option for Depression, Anxiety, PTSD, Chronic Pain and Other Conditions. Get back to living your best life. Quality, safe and effective Ketamine Treatments in Arizona by Redemption Psychiatry.
                    Phone: (480) 576-8666
                    Fax: (888) 979-8197
                    Hours: Monday-Friday 8am to 5pm
                    Gilbert, AZ Office
                    2730 S Val Vista Drive, Suite 146
                    Gilbert, AZ 85295
                    Chandler, AZ Office
                    600 S Dobson Road, Suite 36-E
                    Chandler, AZ 85224
                    Glendale, AZ Office
                    17505 N 79th Avenue, Suite 203
                    Glendale, AZ 85308
                    Phoenix, AZ Office
                    202 E Earll Drive, Suite 160
                    Phoenix, AZ 85012" />
                    <meta property="og:url" content="https://www.rpsychinfusions.com/contact-page" />
                    <meta property="og:site_name" content="Redemption Psychiatry Ketamine Infusions" />
                    <meta property="og:image" content="https://my.datasphere.com/files/mydatasphere/styles/full_640/public/business/images/0419/1555625884_12657239_RedemptionPsychiatry_500x500.png?itok=Qrowvv_y" />
                    <meta property="og:image:secure_url" content="https://my.datasphere.com/files/mydatasphere/styles/full_640/public/business/images/0419/1555625884_12657239_RedemptionPsychiatry_500x500.png?itok=Qrowvv_y" />
                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:title" content="Contact Redemption Psychiatry (480) 576-8666 | Safe and Effective Ketamine Treatments in Arizona" />
                    <meta name="twitter:description" content="An Exceptional Treatment Option for Depression, Anxiety, PTSD, Chronic Pain and Other Conditions. Get back to living your best life. Quality, safe and effective Ketamine Treatments in Arizona by Redemption Psychiatry.
                    Phone: (480) 576-8666
                    Fax: (888) 979-8197
                    Hours: Monday-Friday 8am to 5pm
                    Gilbert, AZ Office
                    2730 S Val Vista Drive, Suite 146
                    Gilbert, AZ 85295
                    Chandler, AZ Office
                    600 S Dobson Road, Suite 36-E
                    Chandler, AZ 85224
                    Glendale, AZ Office
                    17505 N 79th Avenue, Suite 203
                    Glendale, AZ 85308
                    Phoenix, AZ Office
                    202 E Earll Drive, Suite 160
                    Phoenix, AZ 85012" />
                    <meta name="twitter:image" content="https://my.datasphere.com/files/mydatasphere/styles/full_640/public/business/images/0419/1555625884_12657239_RedemptionPsychiatry_500x500.png?itok=Qrowvv_y" />
                    <link rel='dns-prefetch' href='//www.google.com' />
                    <link rel="canonical" href="https://www.rpsychinfusions.com/contact-page" />
                </Helmet>
            </HelmetProvider>
            <Header
                color="transparent"
                rightLinks={<HeaderLinks />}
                fixed
                changeColorOnScroll={{
                    height: 200,
                    color: "white"
                }}
                {...rest}
            />
            <Parallax small filter image={require("assets/img/computer-desk-laptop-stethoscope-48604.jpg")} />
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div>
                    <div className={classes.container}>
                        <div className={classes.profilePrice}>
                            <img src={profile} alt="Redemption Psychiatry Ketamine Infusions" className={imageClasses} />
                        </div>
                        <GridContainer justify="center">
                            <GridItem xs={12} sm={12} md={12}>
                                <div className={classes.nameContact}>
                                    <h3 className={classes.titleContact}>Call us: 480-576-8666-</h3>
                                </div>
                                <h3 className={classes.titleContact}>Redemption Psychiatry Ketamine Clinics</h3>
                                <h5 className={classes.description}>
                                    We have four locations throughout the valley equipped to administer Ketamine, in order to serve you better.<br />
                                    Give us a call if you have any questions or click the pin closest to you for directions to your desired clinic.
                                </h5>
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <CardMapEast />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={6}>
                                <CardMapWest />
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                                <ContactForm />
                            </GridItem>
                        </GridContainer>
                        <br /> <br /> <br />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
