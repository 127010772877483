import { title } from "assets/jss/material-kit-react.js";

const productStyle = {
  section: {
    padding: "0px 0",
    textAlign: "center"
  },
  title: {
    ...title,
    marginBottom: "1rem",
    marginTop: "50px",
    minHeight: "32px",
    textDecoration: "none",
    textAlign: "center"
  },
  title2: {
    ...title,
    marginBottom: "1rem",
    marginTop: "1px",
    minHeight: "32px",
    textDecoration: "none",
    textAlign: "center"
  },
  title3: {
    ...title,
    marginBottom: "1px",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    textAlign: "center"
  },
  description: {
    color: "#999",
    textAlign: "center"
  }
};

export default productStyle;
