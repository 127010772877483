import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ReactTextCollapse from "react-text-collapse";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';


const useStyles = makeStyles(theme => ({
    typography: {
        padding: theme.spacing(2),
        maxWidth: "800px",
    },
    textCenter: {
        textAlign: "center"
    },
    buttonMargin: {
        margin: "5px",
    },
    title: {
        fontWeight: "600",
    },
    showMore : {
        display: "inline",
        position: "relative",
        top: "-7px",
    },
    showLess : {
        display: "inline",
        position: "relative",
        top: "-7px",
    },
    iconHover : {
        "&:hover":{
            color: "ed1212",
            cursor: "pointer",
        }
    }
}));


export default function CostOfTreatment() {
    const classes = useStyles();
    const TEXT_COLLAPSE_OPTIONS = {
        collapse: false, // default state when component rendered
        collapseText:<div className={classes.iconHover}><h6 className={classes.showMore}> Show More </h6><ExpandMoreIcon> </ExpandMoreIcon></div>, // text to show when collapsed
        expandText:<div className={classes.iconHover}><h6 className={classes.showLess}> Show Less </h6><ExpandLessIcon> </ExpandLessIcon></div>, // text to show when expanded
        minHeight: 0, // component height when closed
        maxHeight: 200, // expanded to
        color: "black",
        textStyle: { // pass the css for the collapseText and expandText here
            color: "#003C8F",
            fontSize: "15px",
        }
    }
    return (
        <div>
            <div className={classes.title}>
                •	Lowest cost infusions.
                </div>
            <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                <div className={classes.description}>
                The efficacy of Ketamine infusion therapy has resulted in treatment centers popping up all over the country trying to “cash-in” on the treatment before insurance coverage expands its availability. These clinics generally charge $500-$700 per infusion and offer Ketamine treatments under the care of anesthesiologists while offering no other mental health care options. This means their business is entirely dependent on getting you to sign up for as many infusions as possible at the highest price. Redemption Psychiatry keeps cost low for our clients because we are one of the largest private psychiatric practices in Arizona, and we don’t rely entirely on Ketamine. We perform also the largest volume of Ketamine infusions in the valley, which helps us to keep the cost much lower than other clinics while providing the broadest range of psychiatric treatments with providers who specialize in mental health, not anesthesia, pain medicine, or family practice.
              </div>
            </ReactTextCollapse>
            <br /> <br />
            <div className={classes.title}>
                •	Pay for only the treatments you need.
                </div>
            <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                <div className={classes.description}>
                The last thing we want you to do is to pay for treatments that you don’t need. This means that if you don’t respond to treatment by the third infusion, we generally won’t recommend spending money on additional treatments since the likelihood of responding is too low to warrant further treatment. For those who do respond, maintenance treatments are often recommended to be given monthly, but this is not right for everyone and we will work with you to find the longest interval to maintain efficacy while reducing cost. It would be wholly unethical to prescribe a treatment that does not have the evidence and potential to improve your condition when all other equally viable treatment options have not been fully considered in consultation with a certified mental health specialist, which is why we won’t recommend any unnecessary treatment.
              </div>
            </ReactTextCollapse>
            <br /> <br />
            <div className={classes.title}>
                •	First Infusion is Free.
                </div>
            <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                <div className={classes.description}>
                Trying a Ketamine treatment for the first time is a big decision for most patients and the cost can be a major hurdle. We understand this and offer clients the ability to try the first infusion at no charge following their paid consultation for advanced psychiatric treatments (covered by most private insurances). This evaluation will include a discussion of all available treatment options by a psychiatrist who specializes in treatment-resistant conditions and has expertise in advanced psychiatric treatment options including Ketamine Infusion Therapy, Transcranial Magnetic Stimulation, Theta Burst Stimulation, Deep Brain Stimulation, Electroconvulsive Therapy, etc. This offer is subject to terms and conditions that may change without notice.
              </div>
            </ReactTextCollapse>
            <br /> <br />
            <div className={classes.title}>
                •	Ketamine infusions offered in combination with TMS.
                </div>
            <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                <div className={classes.description}>
                Research published in August 2019 has shown that the combination of Ketamine and TMS treatment has greater response rates, improved tolerability, and more prolonged effects. We have been using combined Ketamine/TMS treatment since summer 2017 and we have already seen these improved benefits for our clients. In an effort to improve outcomes for all our TMS clients, we are currently offering an additional 2 Ketamine infusions with a paid TMS treatment course, covered by most insurances, as medically indicated. For members of the military and their family covered by Tricare, we offer 6 ketamine infusions for free along with an approved course of TMS treatment as part of our commitment to the military. This offer is subject to terms and conditions that may change without notice.
              </div>
            </ReactTextCollapse>
            <br /> <br />
            <div className={classes.title}>
                •	Military and law enforcement discounts.
                </div>
            <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                <div className={classes.description}>
                Patients who have served in the military or law enforcement are at higher risk for PTSD, depression, and other disorders as a direct result of their service protecting all Americans. At Redemption Psychiatry, we show our appreciation by offering a 50% discount on our already low-cost treatment to members of the military and law enforcement whether active or retired. For members of the military and their family covered by Tricare, we offer 6 ketamine infusions for free along with an approved course of TMS treatment as part of our commitment to the military. This offer is subject to terms and conditions that may change without notice.
              </div>
            </ReactTextCollapse>
            <br /> <br />
            <div className={classes.title}>
                •	Healthcare Savings Accounts (HSA) can be used.
                </div>
            <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                <div className={classes.description}>
                If you have an HSA, you can use those funds to pay for ketamine treatment, which can effectively reduce the actual cost.
              </div>
            </ReactTextCollapse>
            <br /> <br />
            <div className={classes.title}>
                •	Payment plans are available.
                </div>
            <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                <div className={classes.description}>
                We understand that it may not be possible to pay for the treatment series all at once, so we allow patients to pay for the treatment over time in installments. Unlike many Ketamine infusion clinics, this is not a credit card plan and we do not charge interest.
              </div>
            </ReactTextCollapse>
        </div>
    );
}