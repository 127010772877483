import React from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import profile from "assets/img/girl.jpg";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import Testimonials from "./Sections/Testimonials.js";
import TestimonialCarousel from "./Sections/Carousel.js";

const useStyles = makeStyles(styles);

export default function TestimonialsPage(props) {
    const classes = useStyles();
    const { ...rest } = props;
    const imageClasses = classNames(
        classes.imgRoundedCircle,
        classes.imgFluid
    );

    return (
        <div>
            <HelmetProvider>
                <Helmet>
                    <title>Redemption Psychiatry Infusions | Patient Testimonials</title>
                    <meta name="description" content="Our patients show us appreciation and gratitude regulary and to celebrate that, the following are real testimonials from our patients here at Redemption Psychiatry who were willing to share their powerful stories. We encourage you to read through others experiences and even share your story with us as well." />
                    <meta property="og:locale" content="en_US" />
                    <meta property="og:type" content="blog" />
                    <meta property="og:title" content="Redemption Psychiatry Infusions | Patient Testimonials" />
                    <meta property="og:description" content="Our patients show us appreciation and gratitude regulary and to celebrate that, the following are real testimonials from our patients here at Redemption Psychiatry who were willing to share their powerful stories. We encourage you to read through others experiences and even share your story with us as well." />
                    <meta property="og:url" content="https://www.rpsychinfusions.com/testimonials-page" />
                    <meta property="og:site_name" content="Redemption Psychiatry Ketamine Infusions" />
                    <meta property="og:image" content="https://my.datasphere.com/files/mydatasphere/styles/full_640/public/business/images/0419/1555625884_12657239_RedemptionPsychiatry_500x500.png?itok=Qrowvv_y" />
                    <meta property="og:image:secure_url" content="https://my.datasphere.com/files/mydatasphere/styles/full_640/public/business/images/0419/1555625884_12657239_RedemptionPsychiatry_500x500.png?itok=Qrowvv_y" />
                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:title" content="Redemption Psychiatry Infusions | Patient Testimonials" />
                    <meta name="twitter:description" content="Our patients show us appreciation and gratitude regulary and to celebrate that, the following are real testimonials from our patients here at Redemption Psychiatry who were willing to share their powerful stories. We encourage you to read through others experiences and even share your story with us as well." />
                    <meta name="twitter:image" content="https://my.datasphere.com/files/mydatasphere/styles/full_640/public/business/images/0419/1555625884_12657239_RedemptionPsychiatry_500x500.png?itok=Qrowvv_y" />
                    <link rel='dns-prefetch' href='//www.google.com' />
                    <link rel="canonical" href="https://www.rpsychinfusions.com/testimonials-page" />
                </Helmet>
            </HelmetProvider>
            <Header
                color="transparent"
                rightLinks={<HeaderLinks />}
                fixed
                changeColorOnScroll={{
                    height: 200,
                    color: "white"
                }}
                {...rest}
            />
            <Parallax small image={require("assets/img/selective-focus-photography-of-hand-1654698.jpg")} />
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.none}>
                    <div className={classes.container}>
                        <GridContainer justify="center">
                            <div className={classes.profile}>
                                <div>
                                    <img src={profile} alt="Redemption Psychiatry Ketamine Treatment Clinic" className={imageClasses} />
                                </div>
                                <div className={classes.nameTestimonial}>
                                    <Testimonials />
                                </div>
                            </div>
                        </GridContainer>
                    </div>
                    <div>
                    <TestimonialCarousel />
                    </div>
                    <br></br><br></br>
                </div>
            </div>
            <Footer />
        </div>
    );
}
