import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    backgroundColor: "#E5E5E5",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    color: "#FFFFFF",
    expansionIndicator: "#FFFFFF",
  },
  summary: {
    backgroundColor: "#2B4E90",
  },
  typographyFAQ: {
    textAlign: "left",
  }
}));

export default function PricingFAQPanel() {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.root}>
        <ExpansionPanel>
          <ExpansionPanelSummary
            className={classes.summary}
            expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>Why doesn’t insurance cover ketamine infusions?</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.typographyFAQ}>
            <Typography>
              Insurance companies generally take a very long time to recognize any new medical treatments. This is true for Ketamine infusion therapy, but unfortunately, there are even more barriers to insurance approval of Ketamine treatments.
            <br /><br />
              •	Generic medications aren’t very profitable. Ketamine has been around for so long that it is generic, produced by several companies, and used very frequently. This means that pharmaceutical companies cannot charge thousands of dollars per use the way they do for most new procedural medications, so they are not willing to spend billions of dollars on the necessary research for FDA approval of a new indication for Ketamine.
            <br /><br />
              •	New FDA indications require investment. The other way to obtain FDA approval is when enough research is generated over several years to establish the new treatment. We are making progress, but Supplemental New Drug applications require sponsors to submit a safety profile for the new treatment as well as provide comparisons with previously approved indications. It seems simple, but the application sponsor is generally a pharmaceutical company willing to go through the effort and expense (estimated around $50M) because they have a financial interest that they plan to recuperate. Generic drugs can be manufactured by anyone, so no single manufacturer could profit from a new indication for Ketamine.
            <br /><br />
              •	No insurance procedures exist. Without FDA approval, insurances generally don’t create the necessary prior authorization procedures, billing codes, and fee schedules for doctors to get reimbursed for the procedure. Insurances can decide to move forward based on the generally accepted off-label use, but that only happens when they have a financial interest in the new treatment like when it saves them more money than it costs.
            <br /><br />
              •	Ketamine use is new in psychiatry. Insurance companies consider many treatments to be specialty-specific, and historically, Ketamine has most commonly been used by anesthesiologists. However, anesthesiologists obviously should not be treating depression any more than psychiatrists should be putting patients to sleep for surgery, but it is generally very difficult for a specialty-specific treatment to suddenly be approved under a new specialty.
            <br /><br />
              •	Unique treatments require new approaches. Ketamine infusion therapy is unique in many ways making it difficult for insurance companies to agree on how to implement it. There just has never been another treatment that is effective for so many mental health disorders, administered by IV under observation in an outpatient setting, and capable of producing safe and highly effective results. In contrast, when a new routine medication is approved, there is already a roadmap on how to implement its use.
            <br /><br />
              •	Certification processes are needed. Only psychiatrists with the proper training and experience should be contracted with insurance companies for administration of Ketamine treatment for mental health disorders, but there isn’t currently any certification recognized by insurance companies to assure doctors are qualified to administer the treatment. This means that unlike the doctors at Redemption Psychiatry who have a wealth of training and experience, insurance coverage may mean that other inexperienced psychiatrists could be financially encouraged to attempt to administer Ketamine infusions without the proper training and no regulation.
            <br /><br />
              Despite all these barriers, we are confident that insurance companies will eventually cover IV Ketamine infusion therapy. It may just take several more years before that happens.
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel>
          <ExpansionPanelSummary
            className={classes.summary}
            expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>What is the cost of treatment?</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.typographyFAQ}>
            <Typography>
              The answer to this question is more complex than just the price of an infusion, which to be straightforward, is currently $375 per treatment discounted from $500. Redemption Psychiatry strives to have the most affordable, highest quality Ketamine treatment available in many ways:
            <br /><br /><br />
              •	Lowest cost infusions. The efficacy of Ketamine infusion therapy has resulted in treatment centers popping up all over the country trying to “cash-in” on the treatment before insurance coverage expands its availability. These clinics generally charge $500-$700 per infusion and offer Ketamine treatments under the care of anesthesiologists while offering no other mental health care options. This means their business is entirely dependent on getting you to sign up for as many infusions as possible at the highest price. Redemption Psychiatry keeps cost low for our clients because we are one of the largest private psychiatric practices in Arizona, and we don’t rely entirely on Ketamine. We perform also the largest volume of Ketamine infusions in the valley, which helps us to keep the cost much lower than other clinics while providing the broadest range of psychiatric treatments with providers who specialize in mental health, not anesthesia, pain medicine, or family practice.
            <br /><br />
              •	Pay for only the treatments you need. The last thing we want you to do is to pay for treatments that you don’t need. This means that if you don’t respond to treatment by the third infusion, we generally won’t recommend spending money on additional treatments since the likelihood of responding is too low to warrant further treatment. For those who do respond, maintenance treatments are often recommended to be given monthly, but this is not right for everyone and we will work with you to find the longest interval to maintain efficacy while reducing cost. It would be wholly unethical to prescribe a treatment that does not have the evidence and potential to improve your condition when all other equally viable treatment options have not been fully considered in consultation with a certified mental health specialist, which is why we won’t recommend any unnecessary treatment.
            <br /><br />
              •	First Infusion is Free. Trying a Ketamine treatment for the first time is a big decision for most patients and the cost can be a major hurdle. We understand this and offer clients the ability to try the first infusion at no charge following their paid consultation for advanced psychiatric treatments (covered by most private insurances). This evaluation will include a discussion of all available treatment options by a psychiatrist who specializes in treatment-resistant conditions and has expertise in advanced psychiatric treatment options including Ketamine Infusion Therapy, Transcranial Magnetic Stimulation, Theta Burst Stimulation, Deep Brain Stimulation, Electroconvulsive Therapy, etc. This offer is subject to terms and conditions that may change without notice.
          	<br /><br />
              • Ketamine infusions offered in combination with TMS. Research published in August 2019 has shown that the combination of Ketamine and TMS treatment has greater response rates, improved tolerability, and more prolonged effects. We have been using combined Ketamine/TMS treatment since summer 2017 and we have already seen these improved benefits for our clients. In an effort to improve outcomes for all our TMS clients, we are currently offering an additional 2 Ketamine infusions with a paid TMS treatment course, covered by most insurances, as medically indicated. For members of the military and their family covered by Tricare, we offer 6 ketamine infusions for free along with an approved course of TMS treatment as part of our commitment to the military. This offer is subject to terms and conditions that may change without notice.
            <br /><br />
              •	Military and law enforcement discounts. Patients who have served in the military or law enforcement are at higher risk for PTSD, depression, and other disorders as a direct result of their service protecting all Americans. At Redemption Psychiatry, we show our appreciation by offering a 50% discount on our already low-cost treatment to members of the military and law enforcement whether active or retired. For members of the military and their family covered by Tricare, we offer 6 ketamine infusions for free along with an approved course of TMS treatment as part of our commitment to the military. This offer is subject to terms and conditions that may change without notice.
            <br /><br />
              •	Healthcare Savings Accounts (HSA) can be used. If you have an HSA, you can use those funds to pay for ketamine treatment, which can effectively reduce the actual cost.
            <br /><br />
              •	Payment plans are available. We understand that it may not be possible to pay for the treatment series all at once, so we allow patients to pay for the treatment over time in installments. Unlike many Ketamine infusion clinics, this is not a credit card plan and we do not charge interest.
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ExpansionPanel>
          <ExpansionPanelSummary
            className={classes.summary}
            expandIcon={<ExpandMoreIcon style={{ color: "#FFFFFF" }} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>What is the cost of not getting treatment?</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.typographyFAQ}>
            <Typography>
              Patients who have responded to Ketamine treatment will tell you that it is well worth the cost, but it can be hard to make the decision for yourself. It may be helpful to consider what your condition currently costs you. Below are a few examples of the cost of ineffective treatment:
            <br /> <br /><br />
              •	Occupational problems. A condition that prevents you from working, finding a job, getting promoted, or even just being productive at work can cost thousands of dollars monthly.
            <br /><br />
              •	Educational problems. Patients may fail classes, drop classes, or withdraw completely due to their symptoms. This can result in lost tuition, repeating courses, lost scholarships, delays in graduating, and even abandoning educational and career goals. The actual cost can be in the tens of thousands, but the intangible costs can be incalculable.
            <br /><br />
              •	Relationship disruption. The impact on friends, family, and romantic relationships cannot be measured, but the cost is very real. Effective treatment may help prevent losing a friend, becoming estranged from family, breaking up a relationship, or even divorcing.
            <br /><br />
              •	Ineffective treatments. Examine how much you’re spending on current treatments that aren’t working. Multiple medication trials, frequent doctor visits, and ongoing therapy appointments can be very costly, even if they are covered by insurance. Most of our patients have been able to reduce or eliminate ineffective treatments and save hundreds of dollars per month.
            <br /><br />
              •	Serious adverse events. Hospitalizations, suicide attempts, and even death can result from inadequate response to treatment. The cost can be financially devastating and potentially immeasurable.
            <br /> <br /><br />
              Ketamine is worth trying. At Redemption Psychiatry, you can try the first infusion for free and will likely know by the third infusion if you should continue treatment. Once you see how much you are personally saving, the cost of treatment is much easier to weigh out.
            </Typography>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
      <br></br><br></br><br></br>
    </div>
  );
}