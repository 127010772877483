import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.8.0";
// pages for this product
import LandingPage from "views/LandingPage/LandingPage.js";
import PricingPage from "views/PricingPage/PricingPage.js";
import PrivacyPolicy from "views/PrivacyPolicy/PrivacyPolicy.js";
import ContactPage from "views/ContactPage/ContactPage.js";
import FaqPage from "views/FaqPage/FaqPage.js";
import TestimonialsPage from "views/TestimonialsPage/TestimonialsPage.js";
// import TeamPage from "views/TeamPage/TeamPage.js";
import DiagnosisPage from "views/DiagnosisPages/DiagnosisGeneral.js";
import ProvidersPage from "views/ProvidersPage/ProvidersPage.js";
import WhatToExpectChooser from "views/WhatToExpectPage/WhatToExpectChooser.js";
import DisclaimerPage from "views/PrivacyPolicy/Disclaimer.js";

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/landing-page" component={LandingPage} />
      <Route path="/pricing-page" component={PricingPage} />
      <Route path="/privacypolicy" component={PrivacyPolicy} />
      <Route path="/disclaimer" component={DisclaimerPage} />
      <Route path="/contact-page" component={ContactPage} />
      <Route path="/faq-page" component={FaqPage} />
      <Route path="/testimonials-page" component={TestimonialsPage} />
      <Route path="/diagnosis-page" component={DiagnosisPage} />
      <Route path="/providers-page" component={ProvidersPage} />
      <Route path="/whattoexpect-page" component={WhatToExpectChooser} />
      <Route path="/" component={LandingPage} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
