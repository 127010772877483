import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ReactTextCollapse from "react-text-collapse";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import DrugDanger from "../../../assets/img/Treatments/Drug_danger_and_dependence.png"


const useStyles = makeStyles(theme => ({
    typography: {
        padding: theme.spacing(2),
        maxWidth: "100%",
    },
    textCenter: {
        textAlign: "center"
    },
    buttonMargin: {
        margin: "5px",
    },
    title: {
        fontWeight: "600",
    },
    showMore: {
        display: "inline",
        position: "relative",
        top: "-7px",
    },
    showLess: {
        display: "inline",
        position: "relative",
        top: "-7px",
    },
    iconHover: {
        "&:hover": {
            color: "ed1212",
            cursor: "pointer",
        }
    }
}));


export default function Addictive() {
    const classes = useStyles();
    const TEXT_COLLAPSE_OPTIONS = {
        collapse: false, // default state when component rendered
        collapseText: <div className={classes.iconHover}><h6 className={classes.showMore}> Show More </h6><ExpandMoreIcon> </ExpandMoreIcon></div>, // text to show when collapsed
        expandText: <div className={classes.iconHover}><h6 className={classes.showLess}> Show Less </h6><ExpandLessIcon> </ExpandLessIcon></div>, // text to show when expanded
        minHeight: 0, // component height when closed
        maxHeight: 200, // expanded to
        color: "black",
        textStyle: { // pass the css for the collapseText and expandText here
            color: "#003C8F",
            fontSize: "15px",
        },
    };
    const TEXT_COLLAPSE_OPTIONS_FAT = {
        collapse: false, // default state when component rendered
        collapseText: <div className={classes.iconHover}><h6 className={classes.showMore}> Show More </h6><ExpandMoreIcon> </ExpandMoreIcon></div>, // text to show when collapsed
        expandText: <div className={classes.iconHover}><h6 className={classes.showLess}> Show Less </h6><ExpandLessIcon> </ExpandLessIcon></div>, // text to show when expanded
        minHeight: 0, // component height when closed
        maxHeight: 500, // expanded to
        color: "black",
        textStyle: { // pass the css for the collapseText and expandText here
            color: "#003C8F",
            fontSize: "15px",
        },
    };
    return (
        <div>
        <div>
            <div className={classes.title}>
                1. Ketamine’s notorious past and historical use.
                </div>
            <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                <div className={classes.description}>
                    This question often stems from ketamine’s colorful past as a street drug, aka “Special K,” “Vitamin K,”  or simply “K.” When people use drugs recreationally (i.e unsupervised, high doses, frequent dosing, self-administered, unmonitored) yes, they can become addicted to it. That being said, ketamine is the most commonly used medicine globally to safely provide sedation for both adults and children to perform medical procedures and operations. (Learn more on our post: 5 Facts To Know About Ketamine’s Past).
              </div>
            </ReactTextCollapse>
            <br /> <br />
            <div className={classes.title}>
                2. Ketamine compared to other substances and medications.
                </div>
            <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS_FAT}>
                <div className={classes.description}>
                    Have you ever smoked a cigarette in your life? Have you ever had an alcoholic beverage? What about a cup of coffee? Are these substances all addictive? Yes, they can be and the potential is there. But not everyone who tries these substances will become addicted to them. Similarly, the addictive potential is there for ketamine but not everyone who tried ketamine will become addicted. Take a look at this chart showing dependence potential of different drugs:
                    <br /><br />
                    <img style={{ display: "block", width: "100%", maxWidth: "800px", margin: "0 auto" }} src={DrugDanger} alt="drug addition levels chart" />
                    <br />
                    Ketamine is in the low dependence potential, compared to both nicotine and alcohol. Ketamine compared with other medications (like morphine and other opioids) used in hospitals has a much lower potential for dependency. So not only is it used to help patients get needed procedures done, like a shoulder reduction, it has a lower risk of dependency compared to morphine!  This is also being field tested in the EMS world, and some paramedics are giving IV ketamine instead of IV opioids for pain.
              </div>
            </ReactTextCollapse>
            <br /> <br />
            <div className={classes.title}>
                3. Ketamine, addiction, and monitored treatment.
                </div>
            <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                <div className={classes.description}>
                    Ketamine infusions start with low doses of ketamine over a prolonged time period. Slowly infused low doses of ketamine avoid the sudden dose of ketamine that recreational users may experience which can create a potentially addictive high.

                    When you are given this transformational medication in a monitored and safe environment over the watchful eye of a healthcare provider, the addictive potential drops further. Here at Redemption Psychiatry, we use ketamine to catalyze your transformation. Our goal is to help get you to a point where you need less and less infusions as you heal.
              </div>
            </ReactTextCollapse>
            <br /> <br />
            <div className={classes.title}>
            4. Comparing risk vs benefits in the age of modern medicine.
                </div>
            <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
                <div className={classes.description}>
                Finally, you have to compare the risk versus benefit of undergoing ketamine infusions.  There is risk (like any medication), but you need to compare it to the benefit one could gain. Take for example chemotherapy, there is a risk of damage to the body, because these powerful drugs not only kill cancer cells (benefit) but will also kill healthy cells (risk). Proper dosing and monitoring further lowers the risk, and increases the overall benefit of receiving the chemotherapy!  Sure, there are risks involved when receiving ketamine infusions, but then there is also the benefit of improved mood and transforming your life!

                We hope this is helpful in answering this question.  Let us know what you think and contact us if you have further questions!
              </div>
            </ReactTextCollapse>
        </div>
        </div>
    );
}