import React from "react";
// react component for creating beautiful carousel
import Carousel from "react-slick";
// material-ui components
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
//images
import image1 from "assets/img/pair-of-red-and-white-low-top-sneakers-914929.jpg";
import image2 from "assets/img/2-people-sitting-with-view-of-yellow-flowers-during-daytime-196666.jpg";
import image3 from "assets/img/close-up-of-tree-against-sky-255441.jpg";
import image4 from "assets/img/prateek-katyal-6jYnKXVxOjc-unsplash.jpg";
import image5 from "assets/img/back-view-beach-clouds-dawn-185801.jpg";
import image6 from "assets/img/balance-macro-ocean-pebbles-235990.jpg";


const styles = theme => ({
  frosty: {
    backgroundColor: "rgba(255, 255, 255, 0.3)",
    borderRadius: "20px !important",
    backdropFilter: "blur(10px)",
    color: "Black",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "180px"
    }
  },
  frostyBlack: {
    backgroundColor: "rgba(255, 255, 255, 0.3)",
    borderRadius: "20px !important",
    backdropFilter: "blur(10px)",
    color: "Black",
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "180px"
    }
  },
  container: {
    maxWidth: "500px"
  },
  quote: {
    fontStyle: "italic",
    fontWeight: "200",
    fontSize: "11px",
    marginTop: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px"
    }
  },  
  quote2: {
    fontStyle: "italic",
    fontWeight: "200",
    fontSize: "9px",
    marginTop: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "8px"
    }
  },
  author: {
    fontStyle: "bold",
    fontWeight: "400",
    fontSize: "14px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px"
    }
  }
});
const useStyles = makeStyles(styles);

export default function TestimonialCarousel() {
  const classes = useStyles();
  const settings = {
    centerMode: true,
    dots: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          variableWidth: false,
          adaptiveHeight: false,
          slidesToShow: 1,
        }
      }
    ]
  };
  return (
    <Carousel {...settings}>
      <div>
        <img
          src={image1}
          alt="Shoes relaxing on hammock beautiful sky background"
          className="slick-image"
        />
        <div className="slick-caption">
          <div className={classes.frostyBlack}>
            <Typography>
              <div className={classes.quote}>
                <br></br>
                "Ketamine changed my life. I've had success on and off with prescription medications but with the help of a series of ketamine treatments beginning last winter, I've been able to step back from suicidal thoughts and get treatment without checking into a behavioral health clinic. 2019 was the first winter in almost 10 years that I was able to enjoy and keep up with my everyday life. I'm genuinely grateful for Corinne and all of the Redemption team."
            </div>
              <div className={classes.author}>
                -Rebecca J.
            </div>
            </Typography>
          </div>
        </div>
      </div>
      <div>
        <img
          src={image2}
          alt="Two people sitting in the back of a car beautiful sunset"
          className="slick-image"
        />
        <div className="slick-caption">
          <div className={classes.frostyBlack}>
            <Typography>
              <div className={classes.quote}>
                <br></br>
                "I started Ketamine in the beginning hesitantly because I was unsure of whether or not it would be a safe option for treatment... I took into account my symptoms and how much I wanted to get better and decided I would rather try and know whether it worked or not than not know at all. I soon found my PTSD receding and other symptoms that were inflamed to lower back to their normal or less than normal amount. The Ketamine also had a bonus for me because I suffer from back pain and during the infusions and after I felt a lot better with my pain levels. I would certainly recommend this treatment for anyone out there who is struggling, I certainly was grasping at straws and I did find some relief in this treatment option."
            </div>
              <div className={classes.author}>
                -Collin B.
            </div>
            </Typography>
          </div>
        </div>
      </div>
      <div>
        <img
          src={image3}
          alt="heart shaped bush"
          className="slick-image"
        />
        <div className="slick-caption">
          <div className={classes.frostyBlack}>
            <Typography>
              <div className={classes.quote}>
                <br></br>
                "Ketamine has given me a new outlook on my life and how strong I am to overcome OCD, panic disorder and PTSD."
            </div>
              <div className={classes.author}>
                -Christina V.
            </div>
            </Typography>
          </div>
        </div>
      </div>
      <div>
        <img
          src={image4}
          alt="laptop resting on desk, you are important sign next"
          className="slick-image"
        />
        <div className="slick-caption">
          <div className={classes.frostyBlack}>
            <Typography>
              <div className={classes.quote2}>
                <br></br>
                "Ketamine is a wonderful treatment. I suffered a horrendus loss: I was told to get in for a treatment, not knowing what it was. After researching it I
                was very scared, I tried to cancel my appointment. However, the Ketamine Nurse, Jolene, asked me to come in just to talk about the process. She
                sat with me for over an hour and explained it twice. She did this two times! Nobody does that! I had plenty of questions and she was able to put my mind at ease. Now everytime I speak to Jolene, it is an absolute joy. I genuinely feel like she is helping me and she really cares about me.
                As for the ketamine itself, just Amazing. I have suffered from depression and anxiety my whole life. A few days after my first treatment people
                started to notice a difference in me. I hadn't notice because I was still grieving. However, I did stop crying everyday. I had a total of three treatments and my depression was at an all time low, and my anxiety attacks have become almost non-existent. This has been a life changing
                experience. My whole life, I have only known Anxiety, depression and stress... This treatment allows me to live a whole new life with a whole
                new outlook I have never known before. When people ask what has change I am actually proud to advocate this revolutionary process. I also recomend this to all my friends and family who suffer from Anxiety and depression."
            </div>
              <div className={classes.author}>
                -Erika H.
            </div>
            </Typography>
          </div>
        </div>
      </div>
      <div>
        <img
          src={image5}
          alt="woman reflecting on cliff overlooking sea amazing sunset"
          className="slick-image"
        />
        <div className="slick-caption">
          <div className={classes.frostyBlack}>
            <Typography>
              <div className={classes.quote}>
                <br></br>
                “I didn't even realize how far down the rabbit hole I fell until after I had my ketamine infusion therapy.  It substantially improved my quality of life.  I've never needed a booster infusion and as a matter of fact, I managed to survive being laid off, breaking my ankle and the death of both of my dogs sequentially within several months without losing it AND without the need for any type of intervention.  I recently joked that that never would have happened if I didn't have my infusion therapy.   I can now live life on life's terms.  I wholeheartedly recommend ketamine infusion therapy if you want your life back ❤️”  
            </div>
              <div className={classes.author}>
                -Heather H.
            </div>
            </Typography>
          </div>
        </div>
      </div>
      <div>
        <img
          src={image6}
          alt="rock stacking formation, balance in life"
          className="slick-image"
        />
        <div className="slick-caption">
          <div className={classes.frostyBlack}>
            <Typography>
              <div className={classes.quote}>
                <br></br>
                "Having suffered through the debilitating effects of depression for nearly 30 years, I know all too well the feeling of desperation that accompanies the search for treatment.  I tried many medications in nearly every class over the course of the last year after my normal “cocktail” of medication stopped working.  Nothing helped – that is, until I began having Ketamine infusions.  The first infusion allowed me to see a blurry light at the end of the tunnel.  I began feeling positive and “normal” again after 2-3 more infusions with the positive effects lasting a little longer each time.  I now get a maintenance infusion every five weeks or so to keep my depression in check and to continue having a positive outlook on life for myself and for my family.  I highly recommend Ketamine treatments from Redemption Psychology for anyone suffering from chronic depression.  It’s a life changer!"
            </div>
              <div className={classes.author}>
                -Brandi K.
            </div>
            </Typography>
          </div>
        </div>
      </div>
    </Carousel>
  );
}