import React from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Typography from '@material-ui/core/Typography';
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import Container from '@material-ui/core/Container';
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import PriceCards from "components/PriceCards/PriceCards.js";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
//sections
import PricingFAQPanel from "components/FAQs/PricingFAQ.js";


const useStyles = makeStyles(styles);

export default function PricingPage(props) {
    const classes = useStyles();
    const { ...rest } = props;

    return (
        <div>
            <HelmetProvider>
                <Helmet>
                    <title>Redemption Psychiatry Infusions | Pricing and Cost Information</title>
                    <meta name="description" content="How much do Ketamine Treatments cost? Redemption Psychiatry strives to have the most affordable, highest quality Ketamine treatment available. Learn more about how our fully trained and highly professional staff can help you determine whether or not Ketamine Infusion Therapy is appropriate for you." />
                    <meta property="og:locale" content="en_US" />
                    <meta property="og:type" content="article" />
                    <meta property="og:title" content="Redemption Psychiatry Infusions | Pricing and Cost Information" />
                    <meta property="og:description" content="How much do Ketamine Treatments cost? Redemption Psychiatry strives to have the most affordable, highest quality Ketamine treatment available. Learn more about how our fully trained and highly professional staff can help you determine whether or not Ketamine Infusion Therapy is appropriate for you." />
                    <meta property="og:url" content="https://www.rpsychinfusions.com/pricing-page" />
                    <meta property="og:site_name" content="Redemption Psychiatry Ketamine Infusions" />
                    <meta property="og:image" content="https://my.datasphere.com/files/mydatasphere/styles/full_640/public/business/images/0419/1555625884_12657239_RedemptionPsychiatry_500x500.png?itok=Qrowvv_y" />
                    <meta property="og:image:secure_url" content="https://my.datasphere.com/files/mydatasphere/styles/full_640/public/business/images/0419/1555625884_12657239_RedemptionPsychiatry_500x500.png?itok=Qrowvv_y" />
                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:title" content="Redemption Psychiatry Infusions | Pricing and Cost Information" />
                    <meta name="twitter:description" content="How much do Ketamine Treatments cost? Redemption Psychiatry strives to have the most affordable, highest quality Ketamine treatment available. Learn more about how our fully trained and highly professional staff can help you determine whether or not Ketamine Infusion Therapy is appropriate for you." />
                    <meta name="twitter:image" content="https://my.datasphere.com/files/mydatasphere/styles/full_640/public/business/images/0419/1555625884_12657239_RedemptionPsychiatry_500x500.png?itok=Qrowvv_y" />
                    <link rel='dns-prefetch' href='//www.google.com' />
                    <link rel="canonical" href="https://www.rpsychinfusions.com/pricing-page" />
                </Helmet>
            </HelmetProvider>
            <Header
                color="transparent"
                rightLinks={<HeaderLinks />}
                fixed
                changeColorOnScroll={{
                    height: 200,
                    color: "white"
                }}
                {...rest}
            />
            <Parallax small filter image={require("assets/img/landing-bg-clouds.jpeg")} />
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div className={classes.backgroundPricing}>
                    <div className={classes.container}>
                        <GridContainer justify="center">
                            <div className={classes.profile}>
                                <Container maxWidth="lg" component="main" className={classes.heroContent}>
                                    <div className={classes.nameNoPic}>
                                        <h2 className={classes.title}>Pricing</h2>
                                        <Typography align="center" color="textPrimary" >
                                            Redemption Psychiatry strives to have the most affordable, highest quality Ketamine treatment available. The efficacy of Ketamine infusion therapy has resulted in treatment centers popping up all over the country trying to “cash-in” on the treatment before insurance coverage expands its availability. These clinics generally charge $500-$700 per infusion and offer Ketamine treatments under the care of anesthesiologists while offering no other mental health care options. This means their business is entirely dependent on getting you to sign up for as many infusions as possible at the highest price. Redemption Psychiatry keeps cost low for our clients because we are one of the largest private psychiatric practices in Arizona, and we don’t rely entirely on Ketamine. We perform also the largest volume of Ketamine infusions in the valley, which helps us to keep the cost much lower than other clinics while providing the broadest range of psychiatric treatments with providers who specialize in <strong>mental health</strong>, not anesthesia, pain medicine, or family practice.
                                        </Typography>
                                        <PriceCards /><br></br><br></br>
                                        <Typography className={classes.frost} component="h3" variant="h3" align="center" color="textPrimary" gutterBottom>
                                            Frequently Asked Questions
                                    </Typography>
                                        <PricingFAQPanel />
                                        <Typography className={classes.frost} variant="h5" align="center" color="textPrimary" component="h5">
                                            Still have questions? Refer to our <a href="/faq-page" className={classes.block}>General FAQ</a> for more information,
                                            <br></br>
                                            or give us a call/visit and ask our expert staff!
                                    </Typography>
                                        <br></br><br></br>
                                    </div>
                                </Container>
                            </div>
                        </GridContainer>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
