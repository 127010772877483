import React from "react";
import { useForm } from 'react-hook-form';
import Axios from "axios";
import NumberFormat from 'react-number-format';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Typography from '@material-ui/core/Typography';
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/workStyle.js";

const useStyles = makeStyles(styles);

export default function WorkSection() {
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm();
  const [tel, setTel] = React.useState("");
  const [open, setOpen] = React.useState(false);
  // const [charsLeft, setCharsLeft] = React.useState("255")
  const [charsUsed, setCharsUsed] = React.useState("0")
  const onSubmit = (data, e) => {
    Axios.post('/api/contactforms/create', data)
    e.target.reset(); // reset after form submit
    setOpen(true);
    setTel("")
  }
  const handleClose = () => {
    setOpen(false);
  };
  const handleWordCount = event => {
    const charCount = event.target.value.length;
    // const charLeft = 255 - charCount;
    setCharsUsed(charCount);
    // setCharsLeft(charLeft);
}
  return (
    <div>
      <GridContainer justify="center">
        <GridItem cs={12} sm={12} md={8}>
          <h2 className={classes.title}>Message us</h2>
          <h4 className={classes.description}>
            If you have a question or would like more information please send us a message with your contact information and someone will get back to you as soon as possible!
          </h4>
          <form onSubmit={handleSubmit(onSubmit)}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4}>
                <TextField
                  label="Your Name"
                  name="name"
                  style={{
                    marginBottom: "20px"
                  }}
                  inputRef={register({
                    required: 'Please enter your name',
                    maxLength: {
                      value: 30,
                      message: 'Max length is 30',
                    },
                  })}
                />
                <br />
                <Typography className={classes.redText}>
                {errors.name && errors.name.message}
                </Typography>
                <br />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <TextField
                  label="Email"
                  name="email"
                  placeholder="example@email.com"
                  style={{
                    marginBottom: "20px"
                  }}
                  inputRef={register({
                    required: 'Please enter your email',
                    pattern: {
                      value: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                      message: 'Invalid email address',
                    },
                  })}
                />
                <br />
                <Typography className={classes.redText}>
                {errors.email && errors.email.message}
                </Typography>
                <br />
              </GridItem>
              <GridItem xs={12} sm={12} md={4}>
                <NumberFormat
                  customInput={TextField}
                  label="Phone Number"
                  value={tel}
                  onChange={e => setTel(e.target.value)}
                  name="tel"
                  placeholder="(555) 555-5555"
                  format="(###) ###-####"
                  mask="_"
                  inputRef={register({
                    pattern: {
                      value: /^(\()?\d{3}(\))?(-|\s)?\d{3}(-|\s)\d{4}$/,
                      message: 'Invalid phone number',
                    },
                  })}
                />
                <br /><br />
                <Typography className={classes.redText}>
                {errors.tel && errors.tel.message}
                </Typography>
                <br />
              </GridItem>
              <GridItem xs={12} sm={12} md={12}>
                <TextField
                  label="Your Message"
                  inputProps={{ maxLength: 255 }}
                  placeholder="Type your message to us here"
                  style={{
                    marginBottom: "20px"
                  }}
                  multiline
                  onChange={handleWordCount}
                  fullWidth
                  rows="5"
                  name="message"
                  inputRef={register({
                    required: 'Please write a message you would like to send us.',
                    maxLength: {
                      value: 255,
                      message: 'Max length is 255 characters',
                    }
                  })}
                />
                <Button type="submit" color="primary" style={{float: "left"}}>Send Message</Button>
                <p style={{textAlign: "right"}}>{charsUsed}/255</p>
                <br />
                <Typography className={classes.redText}>
                {errors.message && errors.message.message}
                </Typography>
                <br />
              </GridItem>
            </GridContainer>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{"Your message has been submitted successfully! "}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                A member of our staff will return your message as soon as possible.
                Thank you and have a nice day!
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary" autoFocus>
                  Okay
                </Button>
              </DialogActions>
            </Dialog>
          </form>
        </GridItem>
      </GridContainer>
    </div>
  );
}