import React from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import PriceCards from "components/PriceCards/PriceCards.js";
import logo from "../../assets/img/RPsychLogoOverUnderTransparent.png";
import StarRateIcon from '@material-ui/icons/StarRate';

// Sections for this page
import WhyChooseUs from "./Sections/WhyChooseUs.js";
import Blogs from "./Sections/Blogs.js";
import PriceNoTreatment from "components/GridList/GridListPriceNoTreatment.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();
  const { ...rest } = props;

  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <title>Redemption Psychiatry Infusions | Ketamine Treatments in Arizona</title>
          <meta name="description" content="An Exceptional Treatment Option for Depression, Anxiety, PTSD, OCD and Other Conditions. Get back to living your best life. Quality, safe and effective Ketamine Infusions in Arizona by Redemption Psychiatry." />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Redemption Psychiatry Infusions | Ketamine Treatments in Arizona" />
          <meta property="og:description" content="An Exceptional Treatment Option for Depression, Anxiety, PTSD, OCD and Other Conditions. Get back to living your best life. Quality, safe and effective Ketamine Infusions in Arizona by Redemption Psychiatry." />
          <meta property="og:url" content="https://www.rpsychinfusions.com/" />
          <meta property="og:site_name" content="Redemption Psychiatry Ketamine Infusions" />
          <meta property="og:image" content="https://my.datasphere.com/files/mydatasphere/styles/full_640/public/business/images/0419/1555625884_12657239_RedemptionPsychiatry_500x500.png?itok=Qrowvv_y" />
          <meta property="og:image:secure_url" content="https://my.datasphere.com/files/mydatasphere/styles/full_640/public/business/images/0419/1555625884_12657239_RedemptionPsychiatry_500x500.png?itok=Qrowvv_y" />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:title" content="Redemption Psychiatry Infusions | Ketamine Treatments in Arizona" />
          <meta name="twitter:description" content="An Exceptional Treatment Option for Depression, Anxiety, PTSD, OCD and Other Conditions. Get back to living your best life. Quality, safe and effective Ketamine Infusions in Arizona by Redemption Psychiatry." />
          <meta name="twitter:image" content="https://my.datasphere.com/files/mydatasphere/styles/full_640/public/business/images/0419/1555625884_12657239_RedemptionPsychiatry_500x500.png?itok=Qrowvv_y" />
          <link rel='dns-prefetch' href='//www.google.com' />
          <link rel="canonical" href="https://www.rpsychinfusions.com/" />
        </Helmet>
      </HelmetProvider>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand={logo}
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />

      <Parallax filter95 image={require("assets/img/landing-bg.png")}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={8}>
              <h1 className={classes.titleHome}>Ketamine Treatments</h1>
              <h2 className={classes.titleHome}><a style={{color: "white"}} href="tel:480-576-8666">(480) 576-8666</a></h2>
              <h4>
                An Amazing New Treatment Option for Depression, Anxiety, PTSD, OCD and Other Conditions. Get back to living your best life.
              </h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.background3}>
          <div className={classes.container}>
            <WhyChooseUs />
            <br></br>
            <br></br>
          </div>
        </div>

        <div className={classes.background}>
          <div className={classes.container}>
            <h2 className={classes.title}>How much does it cost?</h2>
            <h5 className={classes.description}>We have many different payment options, including payment plans, depending on what each patients specific needs are.</h5>
            <PriceCards />
            <br></br><br></br>
            <div className={classes.description}>
            <StarRateIcon />A typical course of treatment is usually approximately $2000 out of pocket, which includes the first probono and 5 follow up treatments. The treatment generally involves an initial round of six infusions and continued maintenance infusions as needed every one to three months until long-term remission is sustained. For this reason, we offer a steep <strong><i>one-time</i></strong> discount for those who know they want to complete a full series.<StarRateIcon />
          <br></br>
            </div>
          </div>
          <br></br>
          <div className={classes.container}>
            <h2 className={classes.title}>What is the cost of NOT getting treatment?</h2>
            <div className={classes.description}>Patients who have responded to Ketamine treatment will tell you that it is well worth the cost, but it can be hard to make the decision for yourself. It may be helpful to consider what your condition currently costs you. Below are a few examples of the cost of ineffective treatment:</div>
            <br></br>
            <PriceNoTreatment />
          </div >
        </div>

        <div className={classes.container}>
          <Blogs />
          <br></br>
          <div style={{ textAlign: "center" }}>
          <h2 className={classes.title}>Check out our video</h2>
          <br></br>
            <Button
              color="danger"
              size="lg"
              href="https://drive.google.com/file/d/1uityK5Qgxwx5Q5C4OOOkE5TBbwL1AR8Q/view"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fas fa-play" />
                Watch video
              </Button>
          </div>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
        </div>
      </div>
      <Footer />
    </div>
  );
}


/* <div className={classes.container}>
          <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
          <CustomTabs
                headerColor="primary"
                tabs={[
                  {
                    tabName: "Occupational problems",
                    tabContent: (
                      <Typography>
                        A condition that prevents you from working, finding a job, getting promoted, or even just being productive at work can cost thousands of dollars monthly.
                     </Typography>
                    )
                  },
                  {
                    tabName: "Educational problems",
                    tabContent: (
                      <Typography>
                        Patients may fail classes, drop classes, or withdraw completely due to their symptoms. This can result in lost tuition, repeating courses, lost scholarships, delays in graduating, and even abandoning educational and career goals. The actual cost can be in the tens of thousands, but the intangible costs can be incalculable.
                      </Typography>
                    )
                  },
                  {
                    tabName: "Relationship disruption",
                    tabContent: (
                      <Typography>
                      The impact on friends, family, and romantic relationships cannot be measured, but the cost is very real. Effective treatment may help prevent losing a friend, becoming estranged from family, breaking up a relationship, or even divorcing.
                    </Typography>
                    )
                  },
                  {
                    tabName: "Ineffective treatments",
                    tabContent: (
                      <Typography className={classes.textCenter}>
                      Examine how much you’re spending on current treatments that aren’t working. Multiple medication trials, frequent doctor visits, and ongoing therapy appointments can be very costly, even if they are covered by insurance. Most of our patients have been able to reduce or eliminate ineffective treatments and save hundreds of dollars per month.
                      </Typography>
                    )
                  },
                  {
                    tabName: "Serious adverse events",
                    tabContent: (
                      <Typography className={classes.textCenter}>
                      Hospitalizations, suicide attempts, and even death can result from inadequate response to treatment. The cost can be financially devastating and potentially immeasurable.
                      </Typography>
                    )
                  },
                ]}
            /></GridItem>
            </GridContainer>
          <br></br><br></br><br></br>
          </div> */