import { title } from "assets/jss/material-kit-react.js";
import {
  primaryColor,
  dangerColor,
  successColor,
  defaultFont
} from "assets/jss/material-kit-react.js";

import image from "assets/img/RLogohalfsize.png";

const workStyle = {
  section: {
    padding: "70px 0",
    color: "#999",
  },
  slickSlide: {
    margin: "0 5px",
  },
  slickList: {
    margin: "0 -5px",
  },
  slickSlider: {
    overflow: "hidden",
  },
  background: {
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    borderRadius: "10px",
    height: "400px",
    background: "linear-gradient(0deg, rgba(0,0,0,1) 28%, rgba(1,22,62,1) 58%, rgba(43,78,144,1) 100%)",
    margin: "5px"
  },
  logo: {
    backgroundImage: "url(" + image + ")",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    height: "300px",
    textAlign: "center",
    marginLeft: "20px",
    borderRadius: "10px",
  },
  title: {
    ...title,
    marginBottom: "50px",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    textAlign: "center"
  },
  titleSlide: {
    ...title,
    marginBottom: "30px",
    color: "white",
    minHeight: "32px",
    textDecoration: "none",
    textAlign: "center",
  },
  description: {
    color: "white",
    textAlign: "center"
  },
  textCenter: {
    textAlign: "center"
  },
  textArea: {
    marginRight: "15px",
    marginLeft: "15px"
  },
  disabled: {
    "&:before": {
      borderColor: "transparent !important"
    }
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: "#D2D2D2 !important",
      borderWidth: "1px !important"
    },
    "&:after": {
      borderColor: primaryColor
    }
  },
  underlineError: {
    "&:after": {
      borderColor: dangerColor
    }
  },
  underlineSuccess: {
    "&:after": {
      borderColor: successColor
    }
  },
  whiteUnderline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: "#FFFFFF"
    },
    "&:after": {
      borderColor: "#FFFFFF"
    }
  },
  labelRoot: {
    ...defaultFont,
    color: "#AAAAAA !important",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "1.42857",
    top: "10px",
    letterSpacing: "unset",
    "& + $underline": {
      marginTop: "0px"
    }
  },
  labelRootError: {
    color: dangerColor + " !important"
  },
  labelRootSuccess: {
    color: successColor + " !important"
  },
  formControl: {
    margin: "0 0 17px 0",
    paddingTop: "27px",
    position: "relative",
    "& svg,& .fab,& .far,& .fal,& .fas,& .material-icons": {
      color: "#495057"
    }
  },
  input1: {
    color: "#495057",
    height: "unset",
    "&,&::placeholder": {
      fontSize: "14px",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: "400",
      lineHeight: "1.42857",
      opacity: "1"
    },
    "&::placeholder": {
      color: "#AAAAAA"
    }
  },
  whiteInput: {
    "&,&::placeholder": {
      color: "#FFFFFF",
      opacity: "1"
    }
  },
  redText: {
    color: "red"
  }
};

export default workStyle;
