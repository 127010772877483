import React from "react";
import { Helmet, HelmetProvider } from 'react-helmet-async';
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import Container from '@material-ui/core/Container';
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import profile from "assets/img/RLogohalfsize.png";
import styles from "assets/jss/material-kit-react/views/profilePage.js";
//sections
import ProviderForm from "./Sections/ProviderForm.js"

const useStyles = makeStyles(styles);

export default function PricingPage(props) {
    const classes = useStyles();
    const { ...rest } = props;
    const imageClasses = classNames(
        classes.imgRounded,
        classes.imgFluid
    );
    return (
        <div>
            <HelmetProvider>
                <Helmet>
                    <title>Redemption Psychiatry | Provider Information</title>
                    <meta name="description" content="Fellow providers, do you have patients you would like to refer for Ketamine or TMS treatment but don't have the staff/equipment to do it in your office? We would love to coordinate care! Have your patients come to our office just to receive our advanced treatments, then return to you for follow up." />
                    <meta property="og:locale" content="en_US" />
                    <meta property="og:type" content="company" />
                    <meta property="og:title" content="Redemption Psychiatry | Provider Information" />
                    <meta property="og:description" content="Fellow providers, do you have patients you would like to refer for Ketamine or TMS treatment but don't have the staff/equipment to do it in your office? We would love to coordinate care! Have your patients come to our office just to receive our advanced treatments, then return to you for follow up." />
                    <meta property="og:url" content="https://www.rpsychinfusions.com/providers-page" />
                    <meta property="og:site_name" content="Redemption Psychiatry Ketamine Infusions" />
                    <meta property="og:image" content="https://my.datasphere.com/files/mydatasphere/styles/full_640/public/business/images/0419/1555625884_12657239_RedemptionPsychiatry_500x500.png?itok=Qrowvv_y" />
                    <meta property="og:image:secure_url" content="https://my.datasphere.com/files/mydatasphere/styles/full_640/public/business/images/0419/1555625884_12657239_RedemptionPsychiatry_500x500.png?itok=Qrowvv_y" />
                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:title" content="Redemption Psychiatry | Provider Information" />
                    <meta name="twitter:description" content="Fellow providers, do you have patients you would like to refer for Ketamine or TMS treatment but don't have the staff/equipment to do it in your office? We would love to coordinate care! Have your patients come to our office just to receive our advanced treatments, then return to you for follow up." />
                    <meta name="twitter:image" content="https://my.datasphere.com/files/mydatasphere/styles/full_640/public/business/images/0419/1555625884_12657239_RedemptionPsychiatry_500x500.png?itok=Qrowvv_y" />
                    <link rel='dns-prefetch' href='//www.google.com' />
                    <link rel="canonical" href="https://www.rpsychinfusions.com/providers-page" />
                </Helmet>
            </HelmetProvider>
            <Header
                color="transparent"
                rightLinks={<HeaderLinks />}
                fixed
                changeColorOnScroll={{
                    height: 200,
                    color: "white"
                }}
                {...rest}
            />
            <Parallax small filter image={require("assets/img/computer-desk-laptop-stethoscope-48604.jpg")} />
            <div className={classNames(classes.main, classes.mainRaised)}>
                <div>
                    <div className={classes.container}>
                        <GridContainer justify="center">
                            <div className={classes.profilePrice}>
                                <div>
                                    <img src={profile} alt="Redemption Psychiatry Ketamine Therapy Center" className={imageClasses} />
                                </div>
                                <Container maxWidth="lg" component="main" className={classes.heroContent}>
                                    <div className={classes.namePrice}>

                                        <h2 className={classes.title}>To Our Colleagues:</h2>
                                        <h5 className={classes.description}>
                                            Have patients you would like to refer for Ketamine or TMS treatment but don't have the staff/equipment to do it in your office?
                                            <br></br>
                                            We would love to coordinate care! Have your patients come to our office just to receive our advanced treatments, then return to you for follow up.
                                        </h5>
                                        <br></br><br></br>
                                    </div>
                                </Container>
                            </div>
                        </GridContainer>
                        <ProviderForm />
                        <br></br><br></br>
                        <br></br><br></br>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}
